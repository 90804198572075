import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import {
  OptionalPbsDynamicWidgetValue,
  PbsDynamicWidgetValue,
} from '../../../models/Widget/PbsDynamicWidgetValue';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { executePageAction, getSelectableData } from '../../../api/resource';
import { ModuleRouteInfo } from '../../../redux/models/state.model';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setRequiredFields } from '../../../redux/reducers/form-builder-reducer';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import style from './SelectWidget.module.css';
import FormikContext from '../../../contexts/FormikContext';
import { ascFindField, getFieldByFullPath } from '../helpers/formikHelpers';
import { InputWidgetTypesEnum } from '../../../models/Widget/WidgetTypes.enum';
import { useSetDefaultWidgetValue } from '../../../hooks/useSetDefaultWidgetValue';
import useContextByFieldName from '../../../hooks/useContextByFieldName';
import { SelectableFilter } from './models/filter';
import { SelectableFilterOperations } from './models/filter-operations';
import { cloneDeep } from 'lodash';
import { SelectableRequest } from './models/request';
import ActionTypeContext from '../../../contexts/ActionTypeContext';
import { WidgetEvent } from '../../../models/Widget/WidgetEvent';
import { AnyObject } from '../../../models/AnyObject';
import CurrentActionContext from '../../../contexts/CurrentActionContext';
import { IsLoadAction } from '../../../utils/isLoadAction';
import usePageDataContext from '../../../hooks/usePageDataContext';
import EventSubscriberContext from '../../../contexts/eventSubscriberContext';
import { RESET_AREA_FORM } from '../../../contexts/RegisterWidgetContext';
import { processValue } from '../../../utils/ProcessFunction';
import { ExecutePageActionParams } from '../../../models/GetWidgetDynamicDataParams';
import { SmartWidget } from '../../../models/Widget/SmartWidget';
import { SelectableResponse } from './models/response';

const requestBody = {
  filter_info: [
    {
      paramName: 'context',
      type: 'EQUALS',
      paramValue: null,
    },
  ],
  page_info: {
    pageIndex: 0,
    pageSize: 1000,
  },
  search_filter: '',
};

export interface selectOption {
  title: string;
  value?: string; // тип string, т.к. все значения загоняем в JSON для корректной работы с объектами
  key: string;
  disabled?: boolean;
}

const initResponse = {
  data: [],
  total_size: 0,
};

export interface SelectWidgetFilterOption {
  configName: string;
  type: string;
  param: string;
}

export interface SelectWidgetOptions {
  [key: string]: any;
  filter: PbsDynamicWidgetValue<SelectWidgetFilterOption>;
  label: PbsDynamicWidgetValue<string>;
  isRequired: PbsDynamicWidgetValue<boolean>;
  fieldName: PbsDynamicWidgetValue<string>;
  parentWidgets: OptionalPbsDynamicWidgetValue<Array<string>>;
  endpoint: PbsDynamicWidgetValue<string>;
  contextFieldPath: OptionalPbsDynamicWidgetValue<string>;
  events: OptionalPbsDynamicWidgetValue<Array<AnyObject>>;
  contextRepeatable: OptionalPbsDynamicWidgetValue<string | null>;
  contextWidget: OptionalPbsDynamicWidgetValue<string | null>;
  smartWidget: OptionalPbsDynamicWidgetValue<SmartWidget>;
}

const SelectWidget: FC<WidgetProps<SelectWidgetOptions>> = (props) => {
  const [
    label,
    isRequired,
    fieldName,
    parentWidgets,
    endpoint,
    contextFieldPath,
    actions,
    dataSource,
    events,
    contextRepeatable,
    contextWidget,
    filter,
    smartWidget,
    height,
    width,
    labelWidth,
    inputWidth,
  ] = valueFromConfig(
    props.config?.options,
    'label',
    'isRequired',
    'fieldName',
    'parentWidgets',
    'endpoint',
    'contextFieldPath',
    'actions',
    'dataSource',
    'events',
    'contextRepeatable',
    'contextWidget',
    'filter',
    'smartWidget',
    'height',
    'width',
    'labelWidth',
    'inputWidth',
  );

  const isMultiple = props.config.type === InputWidgetTypesEnum.SELECT_MULTIPLE;

  const dispatch = useDispatch();

  const eventHandler = useContext(ActionTypeContext);
  const currentAction = useContext(CurrentActionContext);
  const formikFromContext = useContext(FormikContext);
  const eventSubscriber = useContext(EventSubscriberContext);

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<SelectableResponse>(initResponse);
  const [firstValueInit, setFirstValueInit] = useState<boolean>(true);
  const [options, setOptions] = useState<Array<selectOption>>([]);
  const [fullPath, setFullPath] = useState<string>(`${props.config.formPath}.${fieldName}`);
  const [value, setValue] = useState<any>(isMultiple ? [] : undefined);
  const [smartSelfValue, setSmartSelfValue] = useState<any>(value);
  const [smartState, setSmartState] = useState<boolean>(false);
  const [smartWidgetLabel, setSmartWidgetLabel] = useState<string>('');
  const [selfSmart, setSelfSmart] = useState<boolean>(false);

  const { moduleKey, id, type, configPath } = useParams() as unknown as ModuleRouteInfo;

  const inputRef = useRef<any>(null);

  useSetDefaultWidgetValue(fullPath);
  const context = useContextByFieldName(contextFieldPath);
  const pageDataContext = usePageDataContext(contextFieldPath);

  const parentWidgetsStringValues = (parentWidgets || [])
    .map((widgetField: string) =>
      ascFindField(
        formikFromContext?.formik?.values || {},
        props.config.formPath || '',
        widgetField,
      ),
    )
    .join('');

  useEffect(() => {
    if (smartState) {
      setSmartSelfValue(value);
    }
  }, [smartState]);

  useEffect(() => {
    if (!smartWidget || !options.length || options?.[0]?.key === 'noData') {
      return;
    }

    setSelfSmart(smartWidget.changeEditStateActionEvent === 'ON_CLICK');

    const parsedValues = isMultiple
      ? (value || []).map((v: unknown) => JSON.stringify(v))
      : [JSON.stringify(value)];
    const selectedOptionsTitle = parsedValues
      .map((v: string) => (options || []).find((option) => option.value === v)?.title || v)
      .join(', ');

    setSmartWidgetLabel(selectedOptionsTitle);
  }, [value, options, smartWidget, isMultiple]);

  useEffect(() => {
    setFullPath(`${props.config.formPath}.${fieldName}`);
  }, [props.config.formPath, fieldName]);

  useEffect(() => {
    if (dataSource?.length && dataSource[0].args.length && !fullPath.startsWith('.')) {
      const data = getFieldByFullPath(
        formikFromContext?.formik?.values || {},
        fullPath.split('.')[0], // позже это может очень больно аукнуться
      );

      const processedData = processValue(data, dataSource);
      setValue(processedData);
    } else {
      setValue(
        getFieldByFullPath(formikFromContext?.formik?.values || {}, fullPath) ||
          (isMultiple ? [] : undefined),
      );
    }
  }, [formikFromContext?.formik?.values, fullPath, isMultiple, dataSource]);

  useEffect(() => {
    if (contextRepeatable && contextWidget && formikFromContext?.formik?.values) {
      let result: Array<AnyObject> = [];
      setLoading(false);

      const findNestedArray = (obj: AnyObject, field: string) => {
        Object.keys(obj).forEach((key) => {
          const value = obj[key];
          if (field === key && Array.isArray(value)) {
            result = value;
          }
          if (value && typeof value === 'object') {
            findNestedArray(value, field);
          }
        });
      };
      findNestedArray(formikFromContext?.formik?.values || {}, contextRepeatable);

      if (result.length) {
        setResponse({
          ...response,
          data: result
            .map((item: AnyObject) => {
              return {
                title: item[contextWidget],
                id: {
                  id: item[contextWidget],
                },
              };
            })
            .filter((item) => item.id.id) as any,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikFromContext?.formik?.values]);

  useEffect(() => {
    if (response.data?.length) {
      setOptions(
        response.data.map((item: any) => ({
          key: item.title,
          title: item.title,
          value: JSON.stringify(item.id.id),
        })),
      );
    } else {
      setOptions([
        {
          title: 'Нет данных',
          key: 'noData',
          disabled: true,
        },
      ]);
    }
  }, [response]);

  useEffect(() => {
    if (parentWidgets?.length && endpoint) {
      const parentsFilters = parentWidgets.map((widgetField: string) => ({
        paramName: widgetField,
        paramValue: ascFindField(
          formikFromContext?.formik?.values || {},
          props.config.formPath || '',
          widgetField,
        ),
        type: SelectableFilterOperations.EQUALS,
      }));

      const newRequestBody: SelectableRequest = {
        ...requestBody,
        filter_info: setupFilters().concat(parentsFilters),
      };

      getSelectableData(moduleKey, endpoint, newRequestBody)
        .then((data) => {
          setResponse(data);
        })
        .catch(() => {
          // setResponse(initResponse)
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentWidgetsStringValues]);

  const setupFilters = (): Array<SelectableFilter> => {
    // TODO добиться подробной аналитики по формированию фильтров селекта и пререписать с пониманием происходящего
    const filters: Array<SelectableFilter> = [
      {
        paramName: 'context',
        type: SelectableFilterOperations.EQUALS,
        paramValue: context || null,
      },
    ];

    const initialData = cloneDeep(
      getFieldByFullPath(formikFromContext?.formik?.values || {}, `${props.config.formPath}`),
    );

    let filterValue;
    if (filter) {
      const { configName, type, param } = filter;
      if (configName || type || param) {
        filterValue = {
          configName,
          type,
          param,
        };
      }
    } else if (initialData) {
      filterValue = initialData;
    }
    const initDataFilter: SelectableFilter = {
      paramName: 'id',
      type: SelectableFilterOperations.EQUALS,
      paramValue: filterValue,
    };
    filters.push(initDataFilter);

    return filters;
  };

  useEffect(() => {
    if (!parentWidgets?.length && endpoint) {
      getSelectableData(moduleKey, endpoint, { ...requestBody, filter_info: setupFilters() })
        .then((data) => {
          setResponse(data);
        })
        .catch(() => {
          // setResponse(initResponse)
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, pageDataContext]);

  useEffect(() => {
    if (isRequired) {
      dispatch(setRequiredFields(fieldName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config]);

  useEffect(() => {
    if (value && firstValueInit && IsLoadAction(currentAction.actionType)) {
      setFirstValueInit(() => false);
      onChangeAction(value, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, firstValueInit]);

  const onChangeAction = (value: any, fromInit: boolean = false) => {
    const clickAction = (events || []).find((event: WidgetEvent) => event.eventType === 'ON_CLICK');
    if (clickAction && (value?.configName || value?.id || value?.type)) {
      if (
        clickAction.actions[0]?.actionType === 'LOAD_DATA_AND_DISPLAY_FORM_ON_PAGE' &&
        !fromInit
      ) {
        // если экшен происходит не при ините - нужно заресетить фору
        eventSubscriber?.[`${RESET_AREA_FORM}${clickAction.actions[0].options.targetArea}`]?.();
      }

      setTimeout(() => {
        const { configName, id, type } = value;
        eventHandler(clickAction.actions[0], [configName, id, type]);
      }, 100);
    }
  };

  const onBlur = () => {
    if (!selfSmart) {
      return;
    }

    const params: ExecutePageActionParams = {
      configName: configPath,
      id,
      type,
      actionName: smartWidget.submitActionName,
    };

    setSmartState(false);
    executePageAction(moduleKey, params, { main: { [fieldName]: value } }).then((response) => {
      if (response) {
        setValue(smartSelfValue);
      }
      setSmartSelfValue(value);
    });
  };

  const smartLabelClick = () => {
    if (selfSmart) {
      setSmartState(true);

      setTimeout(() => {
        inputRef?.current?.click?.();
      }, 100);
    }
  };

  return (
    <div className={style.selectContainer} style={{ width, height }}>
      <Box display="flex" alignItems="center" style={{ width: labelWidth }}>
        <InputLabel>{label}</InputLabel>
        {isRequired && (
          <Typography variant="inherit" color="error">
            *
          </Typography>
        )}
      </Box>
      <FormControl style={{ width: inputWidth }}>
        {!smartWidget || (smartWidget && smartState) ? (
          <>
            {formikFromContext?.formik ? (
              <Select
                ref={inputRef}
                size="small"
                className={style.selectField}
                labelId="select-label"
                id={fullPath}
                name={fullPath}
                value={
                  isMultiple
                    ? (smartSelfValue || value || []).map((i: unknown) => JSON.stringify(i))
                    : JSON.stringify(smartSelfValue || value)
                }
                onChange={(e) => {
                  const newEvent = {
                    ...e,
                    target: {
                      ...e.target,
                      value: isMultiple
                        ? (e.target.value || []).map((i: string) => JSON.parse(i))
                        : JSON.parse(e.target.value),
                    },
                  };
                  if (selfSmart) {
                    setSmartSelfValue(newEvent.target.value);
                  } else {
                    onChangeAction(newEvent.target.value);
                    formikFromContext.formik.handleChange(newEvent);
                  }
                }}
                disabled={loading}
                multiple={isMultiple}
                displayEmpty
                renderValue={(selectValue) => {
                  const currentValueArray = isMultiple
                    ? selectValue?.length
                      ? selectValue
                      : value.map((item: any) => JSON.stringify(item))
                    : selectValue
                      ? [selectValue]
                      : [JSON.stringify(value)];

                  return (
                    <span>
                      {currentValueArray.map((v: string) => (
                        <span key={v}>
                          {options.find((option) => option.value === v)?.title || v}{' '}
                        </span>
                      ))}
                    </span>
                  );
                }}
                onBlur={(e) => {
                  onBlur();
                  formikFromContext!.formik.handleBlur(e);
                }}
                error={
                  formikFromContext!.formik.touched[fieldName] &&
                  Boolean(formikFromContext!.formik.errors[fieldName])
                }
              >
                {loading ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  options.map((option: selectOption) => (
                    <MenuItem
                      key={option.title}
                      value={option.value}
                      disabled={option.disabled}
                      className={style.menuItem}
                    >
                      {option.title}
                    </MenuItem>
                  ))
                )}
              </Select>
            ) : (
              <Select
                size="small"
                className={style.selectField}
                labelId="select-label"
                disabled={loading}
                multiple={isMultiple}
                displayEmpty
                value={[]}
              >
                {options.map((option: selectOption) => (
                  <MenuItem
                    key={option.title}
                    value={option.value}
                    disabled={option.disabled}
                    className={style.menuItem}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            )}
          </>
        ) : (
          <div
            style={{
              cursor: selfSmart ? 'pointer' : undefined,
            }}
            onClick={smartLabelClick}
          >
            {smartWidgetLabel}
          </div>
        )}
      </FormControl>
    </div>
  );
};
export default SelectWidget;
