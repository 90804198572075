import { Button, Flex, Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import { environment } from '../../environments/environments';
import styles from './ErrorPageComponent.module.scss';

const ErrorPageComponent = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const errorMessage = urlParams.get('errorMessage')?.split('"');
  const errorStatus = urlParams.get('errorStatus');
  const errorStatusText = urlParams.get('errorStatusText')?.split('"');

  const token = sessionStorage.getItem('token');

  //TODO вынести в файл
  const exitClickHandler = () => {
    const logoutURL = environment.logout;
    sessionStorage.clear();
    window.location.href = logoutURL;
  };

  const loginClickHandler = () => {
    const loginURL = environment.address + environment.login;
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('expires_in');
    sessionStorage.removeItem('refresh_token');
    window.location.href = loginURL;
  };

  const [second, setSecond] = useState<number>(1);
  const [progressPercent, setProgressPercent] = useState(99);
  useEffect(() => {
    if (second <= 0) {
      if (token) {
        exitClickHandler();
      } else {
        loginClickHandler();
      }
      return;
    }
    const timer = setInterval(() => {
      setSecond(second - 1);
    }, 1000);
    const timerPercent = setInterval(() => {
      setProgressPercent((prevPercent) => Math.max(prevPercent - 1, 0));
    }, 10.1);
    return () => {
      clearInterval(timer);
      clearInterval(timerPercent);
    };
  }, [second, token]);

  return (
    <div className={styles.containerBlock}>
      <span className={styles.status}>{errorStatus}</span>
      <span className={styles.statusText}>{errorStatusText}</span>
      <hr className={styles.line} />
      <Flex vertical gap="small">
        <Progress
          className={styles.timer}
          percent={progressPercent}
          format={(percent) => `${second}`}
          type="circle"
        />
      </Flex>
      <Button className={styles.authButton} type="primary" onClick={exitClickHandler}>
        {token ? 'Выход' : 'Вход'}
      </Button>
    </div>
  );
};

export default ErrorPageComponent;
