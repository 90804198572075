import { PbsDynamicWidgetValue } from '../models/Widget/PbsDynamicWidgetValue';

interface Options {
  [key: string]: PbsDynamicWidgetValue;
}

// todo дописать функцию, чтоб типизировала полученные переменные
export const valueFromConfig = (options: Options, ...fields: Array<string>) => {
  return (fields || []).map((field) => options?.[field]?.value || null);
};
