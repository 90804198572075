import React, { FC, useContext, useEffect, useState } from 'react';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import PageDataContext from '../../../contexts/PageDataContext';
import { DataFunction } from '../../../models/Widget/DataFunction';
import { processValue } from '../../../utils/ProcessFunction';
import style from './LabelWidget.module.scss';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import { InputLabel } from '@mui/material';

export interface LabelWidgetOptions {
  // TODO разобраться с типом
  [key: string]: PbsDynamicWidgetValue;

  dataSource: PbsDynamicWidgetValue<Array<DataFunction>>;
  className: PbsDynamicWidgetValue<string>;
}

const LabelWidget: FC<WidgetProps<LabelWidgetOptions>> = (props) => {
  const [className, height, width, fieldName, dataSource, fontWeight, fontSize, labelType] =
    valueFromConfig(
      props.config?.options,
      'className',
      'height',
      'width',
      'fieldName',
      'dataSource',
      'fontStyle',
      'fontSize',
      'labelType',
    );

  const formPath = props.config.formPath;

  const pageData = useContext(PageDataContext)?.data;

  const [templateText, setTemplateText] = useState<string>('Данные отсутствуют');

  const [dynamicData, setDynamicData] = useState<any>('');

  const staticData = props.config.value?.trim();

  useEffect(() => {
    if (pageData) {
      const newData = pageData.main ? pageData.main[fieldName] : pageData[fieldName];
      setDynamicData(newData || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData, formPath]);

  useEffect(() => {
    const labelFunction = dataSource || [];

    let dynamicProcessedData = '';
    const dataFromPath = getFieldByFullPath(pageData || {}, `${formPath}.${fieldName}`);

    if (labelFunction.length) {
      const data = getFieldByFullPath(pageData || {}, formPath || '') || {};
      const newData = processValue(data, labelFunction);
      dynamicProcessedData = typeof newData === 'string' ? newData : dataFromPath;
    } else {
      dynamicProcessedData = dataFromPath;
    }
    if (!dynamicProcessedData) {
      const newData = getFieldByFullPath(pageData || {}, fieldName || '');
      dynamicProcessedData = typeof newData === 'string' ? newData : '';
    }

    setTemplateText(` ${dynamicProcessedData || (labelType === 'DYNAMIC' ? '-' : '')}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config, dynamicData]);

  return (
    <div
      className={`${className} ${style.label}`}
      style={{ height, width, fontWeight, fontSize, whiteSpace: 'pre-wrap' }}
    >
      <InputLabel>{staticData}</InputLabel>
      <span>{templateText}</span>
    </div>
  );
};

export default LabelWidget;
