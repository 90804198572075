import React, { CSSProperties, FC, useContext } from 'react';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ConstructorFormikContext from '../../../contexts/ConstructorFormikContext';
import { getFieldByFullPath } from '../../../../helpers/formikHelpers';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  AvailableFunctionsType,
  DataFunction,
  DataSourceFunctionsTranslate,
  FunctionArgument,
} from '../../../../../../models/Widget/DataFunction';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import moduleStyle from './DataSourceConstructor.module.scss';
import { cloneDeep } from 'lodash';
import { AnyObject } from '../../../../../../models/AnyObject';
import ConstructorFieldNamesContext from '../../../contexts/ConstructorFieldNamesContext';

interface DataSourceConstructorProps {
  formPath: string;
  style?: CSSProperties;
}

const defaultFunction: DataFunction = {
  type: AvailableFunctionsType.GET_BY_FIELD_NAME,
  args: [
    {
      type: 'FIELD',
      value: '',
    },
  ],
};

const functionLimits: {
  [key: string]: { availableTypes: Array<'CONST' | 'FIELD'>; min: number; max: number };
} = {
  GET_BY_FIELD_NAME: {
    availableTypes: ['FIELD', 'CONST'],
    min: 1,
    max: Infinity,
  },
  CONCAT: {
    availableTypes: ['FIELD', 'CONST'],
    min: 1,
    max: 1,
  },
  SLICE: {
    availableTypes: ['CONST'],
    min: 1,
    max: 2,
  },
  UPPERCASE: {
    availableTypes: [],
    min: 0,
    max: 0,
  },
  LOWERCASE: {
    availableTypes: [],
    min: 0,
    max: 0,
  },
};

const DataSourceConstructor: FC<DataSourceConstructorProps> = ({ formPath, style }) => {
  const formik = useContext(ConstructorFormikContext);
  const fieldNames = useContext(ConstructorFieldNamesContext);

  const value: Array<DataFunction> = getFieldByFullPath(formik!.values, formPath);

  const addFunction = () => {
    const newFunctionList = [...cloneDeep(value), cloneDeep(defaultFunction)];
    formik!.setFieldValue(formPath, newFunctionList);
  };

  const removeFunction = (functionIndex: number) => {
    const newFunctionList = cloneDeep(value).filter((item, index) => index !== functionIndex);
    formik!.setFieldValue(formPath, newFunctionList);
  };

  const addArgument = (functionIndex: number) => {
    const newValue = cloneDeep(value);
    const argsArray = newValue[functionIndex].args;
    argsArray.push(cloneDeep(argsArray[argsArray.length - 1]));
    formik!.setFieldValue(formPath, newValue);
  };

  const removeArgument = (functionIndex: number, argumentIndex: number) => {
    const newValue = cloneDeep(value);
    newValue[functionIndex].args = newValue[functionIndex].args.filter(
      (item, index) => index !== argumentIndex,
    );
    formik!.setFieldValue(formPath, newValue);
  };

  const handleFunctionTypeChange = (type: AvailableFunctionsType, index: number) => {
    const newValue = cloneDeep(value);
    const newFunctionArgs: Array<FunctionArgument> = [];

    for (let i = 0; i < functionLimits[type].min; i++) {
      newFunctionArgs.push({ type: functionLimits[type].availableTypes[0], value: '' });
    }

    newValue[index] = {
      type: type,
      args: newFunctionArgs,
    };

    formik!.setFieldValue(formPath, newValue);
  };

  return (
    <div style={style} className={moduleStyle.MainAccordionWrapper}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="Label-dada-source"
        >
          <InputLabel>Источник данных</InputLabel>
        </AccordionSummary>

        <div>
          {(value || []).map((item, index: number) => {
            return (
              <div key={index} className={moduleStyle.DataFunctionWrapper}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id={`Label-data-source_${index}`}
                  >
                    <InputLabel
                      className={moduleStyle.ArgumentsTitle}
                      style={{ borderBottom: 'none', padding: 0, width: '100%' }}
                    >
                      <span>
                        {index + 1}. {(DataSourceFunctionsTranslate as AnyObject)[item.type]}
                      </span>
                      <CloseIcon
                        className={moduleStyle.RemoveFunctionIcon}
                        onClick={() => {
                          removeFunction(index);
                        }}
                      />
                    </InputLabel>
                  </AccordionSummary>

                  <div className={moduleStyle.FunctionTypeSelect}>
                    <Select
                      size="small"
                      style={{ width: 220 }}
                      id={`${formPath}.${index}.type`}
                      name={`${formPath}.${index}.type`}
                      value={item.type}
                      onChange={(e) => {
                        handleFunctionTypeChange(e.target.value as AvailableFunctionsType, index);
                      }}
                      onBlur={formik!.handleBlur}
                    >
                      {Object.keys(DataSourceFunctionsTranslate).map((functionType: string) => (
                        <MenuItem key={`${functionType}_${index}`} value={functionType}>
                          {(DataSourceFunctionsTranslate as AnyObject)[functionType]}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  {!!functionLimits[item.type].max && (
                    <>
                      <InputLabel className={moduleStyle.ArgumentsTitle}>
                        Аргументы
                        {item.args.length < functionLimits[item.type].max && (
                          <AddIcon
                            className={moduleStyle.AddArgumentIcon}
                            onClick={() => {
                              addArgument(index);
                            }}
                          />
                        )}
                      </InputLabel>
                      {item.args.map((arg: FunctionArgument, i: number) => {
                        const limits = functionLimits[item.type];

                        return (
                          <div className={moduleStyle.ArgumentWrapper} key={`${arg.type}_${i}`}>
                            {item.args.length > functionLimits[item.type].min && (
                              <CloseIcon
                                className={moduleStyle.RemoveArgumentIcon}
                                onClick={() => {
                                  removeArgument(index, i);
                                }}
                              />
                            )}

                            {limits.availableTypes.length === 2 && (
                              <Select
                                size="small"
                                style={{ width: 187, marginBottom: '2px' }}
                                id={`${formPath}.${index}.args.${i}.type`}
                                name={`${formPath}.${index}.args.${i}.type`}
                                value={arg.type}
                                onChange={formik!.handleChange}
                                onBlur={formik!.handleBlur}
                              >
                                <MenuItem key={'FIELD'} value={'FIELD'}>
                                  Поле
                                </MenuItem>
                                <MenuItem key={'CONST'} value={'CONST'}>
                                  Константа
                                </MenuItem>
                              </Select>
                            )}

                            {arg.type === 'CONST' ? (
                              <TextField
                                size="small"
                                style={{ width: 187 }}
                                id={`${formPath}.${index}.args.${i}.value`}
                                name={`${formPath}.${index}.args.${i}.value`}
                                value={arg.value}
                                onChange={formik!.handleChange}
                                onBlur={formik!.handleBlur}
                                type={'text'}
                                placeholder={'Значение'}
                              />
                            ) : (
                              <Select
                                size="small"
                                style={{ width: 187, marginBottom: '2px' }}
                                id={`${formPath}.${index}.args.${i}.value`}
                                name={`${formPath}.${index}.args.${i}.value`}
                                value={arg.value}
                                onChange={formik!.handleChange}
                                onBlur={formik!.handleBlur}
                                placeholder={'Имя поля'}
                              >
                                {fieldNames.map((fieldName) => (
                                  <MenuItem key={fieldName} value={fieldName}>
                                    {fieldName}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                </Accordion>
              </div>
            );
          })}
          <div className={moduleStyle.AddFunctionWrapper}>
            <AddIcon className={moduleStyle.AddIcon} onClick={addFunction} />
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default DataSourceConstructor;
