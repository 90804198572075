import { createContext } from 'react';
import { EventHandler } from '../components/MainContentWrapper/MainContentWrapper.component';

export const RESET_AREA_FORM = 'RESET_AREA_FORM_';

const RegisterWidgetContext = createContext<(areaName: string, handler: EventHandler) => unknown>(
  () => {},
);

export default RegisterWidgetContext;
