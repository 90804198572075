import React, { FC, useContext } from 'react';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { Input } from 'antd';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import FormikContext from '../../../contexts/FormikContext';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import style from '../InputWidget/InputStringWidget.module.css';
import { Box, InputLabel, Typography } from '@mui/material';
import { useSetDefaultWidgetValue } from '../../../hooks/useSetDefaultWidgetValue';

export interface TextareaWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;
}

const TextareaWidget: FC<WidgetProps<TextareaWidgetOptions>> = (props) => {
  const [label, isRequired, fieldName, height, width, inputWidth, labelWidth, dataSource] =
    valueFromConfig(
      props.config?.options,
      'label',
      'isRequired',
      'fieldName',
      'height',
      'width',
      'inputWidth',
      'labelWidth',
      'dataSource', // TODO
    );
  useSetDefaultWidgetValue(`${props.config.formPath}.${fieldName}`);

  const { TextArea } = Input;

  const formicFromContext = useContext(FormikContext);

  return (
    <div className={style.inputContainer} style={{ width, height }}>
      <Box display="flex" alignItems="center" style={{ width: labelWidth }}>
        <InputLabel>{label}</InputLabel>
        {isRequired && (
          <Typography variant="inherit" color="error">
            *
          </Typography>
        )}
      </Box>
      <div style={{ width: inputWidth }}>
        {formicFromContext?.formik ? (
          <TextArea
            rows={4}
            style={{ width: '100%' }}
            id={`${props.config.formPath}.${fieldName}`}
            name={`${props.config.formPath}.${fieldName}`}
            value={getFieldByFullPath(
              formicFromContext!.formik.values || {},
              `${props.config.formPath}.${fieldName}`,
            )}
            onChange={formicFromContext!.formik.handleChange}
            onBlur={formicFromContext!.formik.handleBlur}
          />
        ) : (
          <TextArea rows={4} style={{ width: '100%' }} />
        )}
      </div>
    </div>
  );
};

export default TextareaWidget;
