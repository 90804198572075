import React, { FC } from 'react';
import { WidgetTypesEnum } from '../../../../models/Widget/WidgetTypes.enum';
import { useDrag } from 'react-dnd';
import style from './WidgetLst.module.css';

interface ConstructorWidgetListItemProps {
  title: string;
  type: WidgetTypesEnum;
}

const ConstructorWidgetListItem: FC<ConstructorWidgetListItemProps> = ({ title, type }) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type,
      item: type,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [type],
  );

  return (
    <div className={style.WidgetItem} ref={dragRef} style={{ opacity }}>
      <span>{title}</span>
    </div>
  );
};

export default ConstructorWidgetListItem;
