import React, { FC, useContext } from 'react';
import { GridTrack } from './GridTrack';
import { GridParameterInput } from './GridParameterInput';
import ConstructorFormikContext from '../ViewConstructorWidget/contexts/ConstructorFormikContext';

interface ConstructorPropertiesProps {
  [key: string]: any;
}

const ConstructorProperties: FC<ConstructorPropertiesProps> = () => {
  const formik = useContext(ConstructorFormikContext);

  return (
    <>
      <h2>Свойства</h2>
      <div style={{ border: '1px solid black' }}>
        <h3>Строки</h3>
        <GridTrack fieldName={'rowSize'} />
      </div>
      <div style={{ border: '1px solid black' }}>
        <h3>Колонки</h3>
        <GridTrack fieldName={'columnSize'} />
      </div>
      <div style={{ border: '1px solid black' }}>
        <h3>Отступы</h3>
        <h6>Отступ строк</h6>
        <GridParameterInput
          value={formik!.values!.rowGap}
          onChange={(newValue: string) => {
            formik!.setFieldValue('rowGap', newValue);
          }}
        />
        <h6>Отступ колонок</h6>
        <GridParameterInput
          value={formik!.values!.columnGap}
          onChange={(newValue: string) => {
            formik!.setFieldValue('columnGap', newValue);
          }}
        />
      </div>
    </>
  );
};

export default ConstructorProperties;
