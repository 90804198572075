import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ModuleRouteInfo, StateModel } from '../../redux/models/state.model';
import { modulesUrlChanged } from '../../redux/reducers/module-route-info-reducer';
import { loadPageConfig } from '../../api/resource';
import { useAppDispatch } from '../../redux/store';
import { notification, Spin } from 'antd';
import { DataPageAction, DynamicPageViewConfig } from '../../models/Page/DynamicPageViewConfig';
import ConfigNameContext from '../../contexts/ConfigNameContext';
import PageView from '../Widgets/PageView/PageView';
import { ActionTypes } from '../../models/enums/EventTypes.enum';
import ActionTypeContext from '../../contexts/ActionTypeContext';
import RegisterWidgetContext from '../../contexts/RegisterWidgetContext';
import {
  EmptyAction,
  WidgetEventAction,
  WidgetSuccessAction,
} from '../../models/Widget/WidgetEventAction';
import { findValueByKey, useHandleSubmitForm } from '../../hooks/useHandleSubmitForm';
import { setModalState } from '../../redux/reducers/modal-state-reducer';
import QueryParamsContext from '../../contexts/QueryParamsContext';
import { useSelector } from 'react-redux';
import ModalWrapper from '../ModalComponent/ModalComponent';
import { resetNotificationMessage } from '../../redux/reducers/notificationMesageReducer';
import { resetFormField } from '../../redux/reducers/form-builder-reducer';
import { resetDataInstance } from '../../redux/reducers/DataInstanceReducer';
import style from './MainContentWrapper.module.scss';
import FormContext, { FormContextType } from '../../contexts/FormContextType';
import { ConfigType } from '../../models/ConfigType';
import { updateData } from '../../redux/reducers/ActionsReducer';
import CurrentActionContext from '../../contexts/CurrentActionContext';
import SetCurrentActionContext from '../../contexts/SetCurrentActionContext';
import EventSubscriberContext from '../../contexts/eventSubscriberContext';
import { setPrevContext } from '../../redux/reducers/PrevContextReducer';

export type EventHandler = (...params: Array<unknown>) => unknown;

export type EventHandlerConfig = {
  [areaName: string]: EventHandler;
};

const emptyContext = { configName: '', id: '', type: '' };

const MainContentWrapperComponent: FC = () => {
  const dispatch = useAppDispatch();

  const params: ModuleRouteInfo = useParams() as unknown as ModuleRouteInfo;
  const { moduleKey } = params;
  const controller = new AbortController();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [context, setContext] = useState<FormContextType>(emptyContext);
  const [modalContext, setModalContext] = useState<FormContextType>(emptyContext);
  const [currentAction, setCurrentAction] = useState<WidgetEventAction>(EmptyAction);
  const [pageConfig, setPageConfig] = useState<DynamicPageViewConfig>();
  const [queryParams, setQueryParams] = useState<ConfigType | null>(null);
  const [eventSubscribers, setEventSubscribers] = useState<EventHandlerConfig>({});

  const modalState = useSelector((state: StateModel) => state.modalState);
  const notificationMessage = useSelector((state: StateModel) => state.notificationMessage);
  const prevContext = useSelector((state: StateModel) => state.prevContext);

  const { executeAction } = useHandleSubmitForm(null);

  useEffect(() => {
    if (modalState.context) {
      const { configName, id, type } = modalState.context as ConfigType;
      if (configName && id && type) {
        setModalContext({ configName, id, type });
      }
    }
  }, [modalState.context]);

  useEffect(() => {
    if (notificationMessage) {
      const type = notificationMessage.success ? 'success' : 'error';

      notification.open({
        type,
        message: notificationMessage.message,
      });

      dispatch(resetNotificationMessage());
    }
  }, [notificationMessage, dispatch]);

  const registerSubscriber = (areaName: string, handler: EventHandler) => {
    setEventSubscribers((eventSubscribers) => ({ ...eventSubscribers, [areaName]: handler }));
  };

  const successActionsHandler = (successActions: Array<WidgetSuccessAction>, result: any) => {
    successActions.forEach((success) => {
      switch (success.actionType) {
        case ActionTypes.UPDATE_DATA: {
          dispatch(updateData(success));
          dispatch(resetFormField());
          break;
        }
        case ActionTypes.UPDATE_FORM_DATA: {
          // this.formController.updateFormData(success.targetForm, result.value);
          break;
        }
        case ActionTypes.REDIRECT: {
          const url = success.targetUrl;
          const data = result.value.main;
          const targetName: string = success.targetAreas[0];
          // tslint:disable-next-line:forin
          const valueName = findValueByKey(data, targetName);
          const targetUrl = url.replace('&', valueName);
          navigate(`${targetUrl}`, { relative: 'path' });
          // TODO Логика редиректа
          break;
        }
        case ActionTypes.CREATE: {
          console.log('create');
          break;
        }
        default: {
          break;
        }
      }
    });
  };

  const actionHandler = (action: WidgetEventAction, additionalData?: any) => {
    dispatch(resetDataInstance());
    setCurrentAction(action);

    // хотел нармально отрефакторить - а стало хуже
    if (prevContext.needSetContext && !action.actionType.includes('ON_PAGE')) {
      setContext(prevContext.context as FormContextType);
      dispatch(setPrevContext({ context: emptyContext, needSetContext: false }));
    } else {
      // TODO баг, не всегда подставляется загруженная динамическая форма
      setContext(context);
    }

    new Promise((resolve, reject) => {
      switch (action.actionType) {
        case ActionTypes.LOAD_AND_DISPLAY_WIDGET:
        case ActionTypes.LOAD_DATA_AND_DISPLAY_FORM_ON_PAGE: // а может этот кейс и не сюда...
        case ActionTypes.LOAD_AND_DISPLAY_FORM_ON_PAGE: {
          const queryParams = {
            configName: additionalData[0],
            id: additionalData[1],
            type: additionalData[2],
          };
          loadPageConfig(moduleKey, queryParams, controller.signal)
            .then((config) => {
              if (action.options?.targetArea) {
                const areaName = action.options.targetArea;
                const handler = eventSubscribers[areaName];
                handler(config, additionalData);
                resolve(config);
              }
            })
            .catch(() => {});
          setQueryParams(queryParams);
          break;
        }
        case ActionTypes.SUBMIT_FORM_PAGE:
        case ActionTypes.SUBMIT_FORM:
        case ActionTypes.SUBMIT_FORM_AND_WAIT: {
          executeAction(action, additionalData)
            .then((result: DataPageAction | void) => {
              resolve(result);
            })
            .catch((e: any) => {
              reject(e);
            });
          break;
        }
        case ActionTypes.DISPLAY_FORM_PAGE:
        case ActionTypes.LOAD_DATA_AND_DISPLAY_FORM_PAGE: {
          const { configName, id, type } = action.options as FormContextType;
          const links = `${configName}/${id}/${type}`;
          navigate(`${links}`, { relative: 'path' });
          setContext({ configName, id, type });
          resolve({ configName, id, type });
          break;
        }
        case ActionTypes.CANCEL_FORM:
        case ActionTypes.CANCEL_PAGE: {
          if (modalState.isOpen) {
            dispatch(setModalState(false));
          } else {
            navigate(-1);
          }

          dispatch(resetFormField());
          resolve('');
          break;
        }
        default:
          break;
      }
    })
      .then((result) => {
        successActionsHandler(action.successActions || [], result);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  function loadPage() {
    setLoading(true);

    dispatch(modulesUrlChanged(params));

    const { configPath, id, type } = params;
    const queryParams = { configName: configPath, id, type };

    loadPageConfig(moduleKey, queryParams, controller.signal)
      .then((data) => {
        setPageConfig(data);
        setLoading(false);
      })
      .catch(() => {});

    return () => controller.abort();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadPage(), [params]);

  const handleCancel = () => {
    dispatch(setModalState({ isOpen: false }));
    dispatch(resetFormField());
  };

  return (
    <>
      {!loading && pageConfig && (
        <QueryParamsContext.Provider value={queryParams}>
          <ActionTypeContext.Provider value={actionHandler}>
            <RegisterWidgetContext.Provider value={registerSubscriber}>
              <EventSubscriberContext.Provider value={eventSubscribers}>
                <ConfigNameContext.Provider value={params.configPath}>
                  <FormContext.Provider value={{ context, setContext }}>
                    <CurrentActionContext.Provider value={currentAction}>
                      <SetCurrentActionContext.Provider value={setCurrentAction}>
                        <PageView config={pageConfig} />

                        <FormContext.Provider
                          value={{ context: modalContext, setContext: setModalContext }}
                        >
                          {modalState.isOpen && (
                            <ModalWrapper
                              isOpen={modalState.isOpen}
                              activeAction={modalState.context}
                              onCancel={handleCancel}
                              action={modalState.action}
                            />
                          )}
                        </FormContext.Provider>
                      </SetCurrentActionContext.Provider>
                    </CurrentActionContext.Provider>
                  </FormContext.Provider>
                </ConfigNameContext.Provider>
              </EventSubscriberContext.Provider>
            </RegisterWidgetContext.Provider>
          </ActionTypeContext.Provider>
        </QueryParamsContext.Provider>
      )}
      {loading && (
        <div className={style.loader}>
          <Spin tip="Загрузка" size="large" />
        </div>
      )}
    </>
  );
};

export default MainContentWrapperComponent;
