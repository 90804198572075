import React, { FC } from 'react';
import { WidgetProps } from '../../../../models/Widget/WidgetProps';
import ViewConstructorWidget, { ViewConstructorWidgetOptions } from '../ViewConstructorWidget';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const ViewConstructorDragWrapper: FC<WidgetProps<ViewConstructorWidgetOptions>> = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <ViewConstructorWidget {...props} />
    </DndProvider>
  );
};

export default ViewConstructorDragWrapper;
