import React, { FC, useEffect, useState } from 'react';
import { SizeUnitsEnum } from '../../../models/enums/SizeUnitsEnum';
import { Input } from 'antd';
import { MenuItem, Select } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

interface GridParameterInputProps {
  value: any;
  onChange: (newValue: string) => void;
  onDelete?: (index: number) => void;
  index?: number;
  array?: Array<string>;
}

export const GridParameterInput: FC<GridParameterInputProps> = ({
  value,
  onChange,
  onDelete,
  index,
  array,
}) => {
  // отображает строку с отображением полей строк/колонок

  const sizeUnitsEnum = SizeUnitsEnum;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const digitValue = parseInt(value, 10);
    const unit = value?.match(/[^0-9]+/g)?.[0];
    setInputValue(digitValue);
    setSelectValue(unit);
  });

  const [inputValue, setInputValue] = useState<number | string>('');
  const [selectValue, setSelectValue] = useState<string>(SizeUnitsEnum.FR);

  const handleInput = (event: any) => {
    const value = event.target.value;
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue > 0) {
      setInputValue(parsedValue);
      onChange(parsedValue + (selectValue || '')); // Notify parent of input change
    }
  };

  const handleSelectChange = (event: any) => {
    const newUnit = event.target.value;
    setSelectValue(newUnit);
    onChange(inputValue + newUnit); // Notify parent of unit change
  };

  const handleDelete = () => {
    if (onDelete && typeof index === 'number') {
      onDelete(index);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', width: '100%', backgroundColor: 'lightgray' }}>
        <Input
          style={{ width: '100%' }}
          value={inputValue}
          defaultValue={'1'}
          onChange={handleInput}
        ></Input>
        <Select
          style={{ width: '100%' }}
          value={selectValue}
          defaultValue={'fr'}
          onChange={handleSelectChange}
        >
          {Object.values(sizeUnitsEnum).map((value, i) => (
            <MenuItem key={i} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
        {array && array.length !== 1 && (
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};
