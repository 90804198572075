import React, { FC, useContext, useEffect, useState } from 'react';
import ConstructorFormikContext from '../../contexts/ConstructorFormikContext';
import ConstructorFieldNamesContext from '../../contexts/ConstructorFieldNamesContext';
import { ViewConstructorWidgetFormProps } from '../ViewConstructorForm';
import formStyles from '../ViewConstructorForms.module.scss';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getFieldByFullPath } from '../../../helpers/formikHelpers';
import DataSourceConstructor from './DataSourceConstructor/DataSourceConstructor';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { GridParameterInput } from '../../../ConstructorProperties/GridParameterInput';
import { Checkbox } from 'antd';
import { WidgetTypesEnum } from '../../../../../models/Widget/WidgetTypes.enum';
import { ModuleRouteInfo } from '../../../../../redux/models/state.model';
import { useParams } from 'react-router-dom';
import { getSelectableData } from '../../../../../api/resource';
import { selectOption } from '../../../SelectWidget/SelectWidget';
import { SelectableItemsMapper } from '../../../../../utils/selectableItemsMapper';
import SmartWidgetOption from './SmartWidgetOption/SmartWidgetOption';

const SelectConstructorForm: FC<ViewConstructorWidgetFormProps> = ({ areaPath }) => {
  const formik = useContext(ConstructorFormikContext);
  const fieldNames = useContext(ConstructorFieldNamesContext);

  const params: ModuleRouteInfo = useParams() as unknown as ModuleRouteInfo;

  const [endpointOptions, setEndpointOptions] = useState<Array<selectOption>>([]);

  useEffect(() => {
    getSelectableData(params.moduleKey, 'businessObjectSelectSelectableService', {
      page_info: { pageIndex: 0, pageSize: 1000 },
    }).then((response) => {
      if (response?.data) {
        setEndpointOptions(SelectableItemsMapper(response.data));
      }
    });
  }, []);

  return (
    <div className={formStyles.FormFieldsWrapper}>
      <div>
        <InputLabel>Имя поля</InputLabel>
        <Select
          size="small"
          style={{ width: 220 }}
          id={`${areaPath}.options.fieldName.value`}
          name={`${areaPath}.options.fieldName.value`}
          value={getFieldByFullPath(formik!.values, `${areaPath}.options.fieldName.value`)}
          onChange={formik!.handleChange}
          onBlur={formik!.handleBlur}
        >
          {fieldNames.map((fieldName) => (
            <MenuItem key={fieldName} value={fieldName}>
              {fieldName}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div>
        <InputLabel>Подпись</InputLabel>
        <TextField
          size="small"
          style={{ width: '100%' }}
          id={`${areaPath}.options.label.value`}
          name={`${areaPath}.options.label.value`}
          value={getFieldByFullPath(formik!.values, `${areaPath}.options.label.value`)}
          onChange={formik!.handleChange}
          onBlur={formik!.handleBlur}
          type={'text'}
        />
      </div>

      <div style={{ width: 220 }}>
        <Checkbox
          onChange={formik!.handleChange}
          id={`${areaPath}.options.isRequired.value`}
          name={`${areaPath}.options.isRequired.value`}
          checked={!!getFieldByFullPath(formik!.values, `${areaPath}.options.isRequired.value`)}
        >
          <InputLabel>Обязательное поле</InputLabel>
        </Checkbox>
      </div>

      <div style={{ width: 220 }}>
        <Checkbox
          onChange={(e) => {
            formik!.setFieldValue(
              `${areaPath}.type`,
              e.target.checked ? WidgetTypesEnum.SELECT_MULTIPLE : WidgetTypesEnum.SELECT,
            );
          }}
          checked={
            !(getFieldByFullPath(formik!.values, `${areaPath}.type`) === WidgetTypesEnum.SELECT)
          }
        >
          <InputLabel>Множественный выбор</InputLabel>
        </Checkbox>
      </div>

      {/*не понял что это*/}
      {/*<div>*/}
      {/*    <InputLabel>Датасет</InputLabel>*/}
      {/*    <Select*/}
      {/*        size="small"*/}
      {/*        style={{width: 220}}*/}
      {/*        id={`${areaPath}.options.endpoint.value`}*/}
      {/*        name={`${areaPath}.options.endpoint.value`}*/}
      {/*        value={getFieldByFullPath(formik!.values, `${areaPath}.options.endpoint.value`)}*/}
      {/*        onChange={formik!.handleChange}*/}
      {/*        onBlur={formik!.handleBlur}*/}
      {/*    >*/}
      {/*        {(dataSources || []).map((endpoint) => (*/}
      {/*            <MenuItem key={endpoint.key} value={endpoint.value}>*/}
      {/*                {endpoint.title}*/}
      {/*            </MenuItem>*/}
      {/*        ))}*/}
      {/*    </Select>*/}
      {/*</div>*/}

      <div>
        <InputLabel>Endpoint</InputLabel>
        <Select
          size="small"
          style={{ width: 220 }}
          id={`${areaPath}.options.endpoint.value`}
          name={`${areaPath}.options.endpoint.value`}
          value={getFieldByFullPath(formik!.values, `${areaPath}.options.endpoint.value`)}
          onChange={formik!.handleChange}
          onBlur={formik!.handleBlur}
        >
          {(endpointOptions || []).map((endpoint) => (
            <MenuItem key={endpoint.key} value={endpoint.value}>
              {endpoint.title}
            </MenuItem>
          ))}
        </Select>
      </div>

      <SmartWidgetOption areaPath={areaPath} />

      <DataSourceConstructor
        style={{ width: '90%' }}
        formPath={`${areaPath}.options.dataSource.value`}
      />

      <div className={formStyles.WidgetStyleAccordionWrapper}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id={`Textarea-style`}
          >
            <InputLabel style={{ borderBottom: 'none', padding: 0, width: '100%' }}>
              Стлили
            </InputLabel>
          </AccordionSummary>

          <div className={formStyles.WidgetStyleWrapper}>
            <div>
              <InputLabel>Ширина</InputLabel>
              <GridParameterInput
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.width.value`)}
                onChange={(newValue: string) => {
                  formik!.setFieldValue(`${areaPath}.options.width.value`, newValue);
                }}
              />
            </div>

            <div>
              <InputLabel>Высота</InputLabel>
              <GridParameterInput
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.height.value`)}
                onChange={(newValue: string) => {
                  formik!.setFieldValue(`${areaPath}.options.height.value`, newValue);
                }}
              />
            </div>

            <div>
              <InputLabel>Ширина лейбла</InputLabel>
              <GridParameterInput
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.labelWidth.value`)}
                onChange={(newValue: string) => {
                  formik!.setFieldValue(`${areaPath}.options.labelWidth.value`, newValue);
                }}
              />
            </div>

            <div>
              <InputLabel>Ширина инпута</InputLabel>
              <GridParameterInput
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.inputWidth.value`)}
                onChange={(newValue: string) => {
                  formik!.setFieldValue(`${areaPath}.options.inputWidth.value`, newValue);
                }}
              />
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default SelectConstructorForm;
