import React from 'react';
import TreeListView, { TreeListOptions } from '../components/Widgets/TreeListView/TreeListView';
import { PageWidgetConfig, WidgetConfig } from '../models/Widget/WidgetConfig';
import EmptyWidget from '../components/Widgets/EmptyWidget/EmptyWidget';
import PageView, { PageViewOptions } from '../components/Widgets/PageView/PageView';
import { InputWidgetTypesEnum, WidgetTypesEnum } from '../models/Widget/WidgetTypes.enum';
import ButtonWidget, { ButtonWidgetOptions } from '../components/Widgets/ButtonWidget/ButtonWidget';
import LabelWidget, { LabelWidgetOptions } from '../components/Widgets/LabelWidget/LabelWidget';
import TableWidget, { TableWidgetOptions } from '../components/Widgets/TableWidget/TableWidget';
import TabsWidget, { TabsWidgetOptions } from '../components/Widgets/TabsWidget/TabsWidget';
import FormInnerGrid, {
  FormInnerGridOptions,
} from '../components/Widgets/FormInnerGrid/FormInnerGrid';
import BlankWidget, { BlankWidgetOptions } from '../components/Widgets/BlankWidget/BlankWidget';
import InputStringWidget, {
  InputWidgetOptions,
} from '../components/Widgets/InputWidget/InputStringWidget';
import CheckboxWidget, {
  CheckboxWidgetOptions,
} from '../components/Widgets/CheckboxWidget/CheckboxWidget';
import SelectWidget, { SelectWidgetOptions } from '../components/Widgets/SelectWidget/SelectWidget';
import TextareaWidget, {
  TextareaWidgetOptions,
} from '../components/Widgets/TextAreaWidget/TextareaWidget';
import UploadFileWidget, {
  UploadFileWidgetOptions,
} from '../components/Widgets/UploadFileWidget/UploadFileWidget';
import MonacoEditorWidget, {
  MonacoEditorWidgetProps,
} from '../components/Widgets/MonacoEditorWidget/MonacoEditorWidget';
import SqlConstructorWidget, {
  SqlConstructorWidgetOptions,
} from '../components/Widgets/SqlConstructorWidget/SqlConstructorWidget';
import { ViewConstructorWidgetOptions } from '../components/Widgets/ViewConstructorWidget/ViewConstructorWidget';
import InputDateWidget, {
  InputDateWidgetOptions,
} from '../components/Widgets/InputDateWidget/InputDateWidget';
import ViewConstructorDragWrapper from '../components/Widgets/ViewConstructorWidget/ViewConstructorDragWrapper/ViewConstructorDragWrapper';

const WidgetFactory = (config: WidgetConfig<unknown>, areaName: string, formPath: string = '') => {
  switch (config?.type || WidgetTypesEnum.BLANK) {
    case WidgetTypesEnum.PAGE_VIEW:
      return (
        <PageView
          config={{ ...config, formPath } as PageWidgetConfig<PageViewOptions>}
          areaName={areaName}
        />
      );
    case WidgetTypesEnum.TREE_LIST_VIEW:
      return (
        <TreeListView config={config as PageWidgetConfig<TreeListOptions>} areaName={areaName} />
      );
    case WidgetTypesEnum.LABEL:
      return <LabelWidget config={{ ...config, formPath } as WidgetConfig<LabelWidgetOptions>} />;
    case WidgetTypesEnum.BUTTON:
      return <ButtonWidget config={config as WidgetConfig<ButtonWidgetOptions>} />;
    case WidgetTypesEnum.TABLE:
      return (
        <TableWidget config={config as PageWidgetConfig<TableWidgetOptions>} areaName={areaName} />
      );
    case WidgetTypesEnum.TABS:
      return <TabsWidget config={config as WidgetConfig<TabsWidgetOptions>} />;
    case WidgetTypesEnum.FORMS_INNER_GRID:
      return (
        <FormInnerGrid config={{ ...config, formPath } as WidgetConfig<FormInnerGridOptions>} />
      );
    case WidgetTypesEnum.INPUT_STRING:
    case WidgetTypesEnum.INPUT_NUMBER:
      return (
        <InputStringWidget config={{ ...config, formPath } as WidgetConfig<InputWidgetOptions>} />
      );
    case WidgetTypesEnum.INPUT_BOOLEAN:
      return (
        <CheckboxWidget config={{ ...config, formPath } as WidgetConfig<CheckboxWidgetOptions>} />
      );
    case WidgetTypesEnum.SELECT:
    case InputWidgetTypesEnum.SELECT_MULTIPLE:
      return <SelectWidget config={{ ...config, formPath } as WidgetConfig<SelectWidgetOptions>} />;
    case WidgetTypesEnum.TEXTAREA:
      return (
        <TextareaWidget config={{ ...config, formPath } as WidgetConfig<TextareaWidgetOptions>} />
      );
    case WidgetTypesEnum.BLANK:
    case WidgetTypesEnum.NONE:
      return <BlankWidget config={{ ...config, formPath } as WidgetConfig<BlankWidgetOptions>} />;
    case WidgetTypesEnum.UPLOAD_FILE:
      return (
        <UploadFileWidget
          config={{ ...config, formPath } as WidgetConfig<UploadFileWidgetOptions>}
        />
      );
    case WidgetTypesEnum.SQL_CONSTRUCTOR:
      return (
        <SqlConstructorWidget
          config={{ ...config, formPath } as WidgetConfig<SqlConstructorWidgetOptions>}
        />
      );
    case WidgetTypesEnum.VIEW_CONSTRUCTOR:
      return (
        <ViewConstructorDragWrapper
          config={{ ...config, formPath } as WidgetConfig<ViewConstructorWidgetOptions>}
        />
      );
    case WidgetTypesEnum.INPUT_DATE:
      return (
        <InputDateWidget config={{ ...config, formPath } as WidgetConfig<InputDateWidgetOptions>} />
      );
    case WidgetTypesEnum.CODE_EDITOR_GROOVY:
    case WidgetTypesEnum.INPUT_JSON:
      return (
        <MonacoEditorWidget
          config={{ ...config, formPath } as WidgetConfig<MonacoEditorWidgetProps>}
        />
      );
    default:
      return <EmptyWidget widgetType={config.type} />;
  }
};

export default WidgetFactory;
