import React, { FC, useContext } from 'react';
import ConstructorFormikContext from '../contexts/ConstructorFormikContext';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { AnyObject } from '../../../../models/AnyObject';
import { WidgetsTranslate } from '../VidgetList/WidgetsTranslate';
import { WidgetTypesEnum } from '../../../../models/Widget/WidgetTypes.enum';

interface ViewConstructorFormTreeProps {
  currentFormPath: string;
  handleTreeSelect: (
    widgetPath: string,
    type: 'WIDGET' | 'AREA',
    widgetType: WidgetTypesEnum,
  ) => void;
}

const ViewConstructorFormTree: FC<ViewConstructorFormTreeProps> = ({
  currentFormPath,
  handleTreeSelect,
}) => {
  const formic = useContext(ConstructorFormikContext);

  return (
    <div>
      <h1 style={{ marginBottom: '15px' }}>Дерево</h1>
      <div>
        <SimpleTreeView multiSelect={false} selectedItems={currentFormPath || undefined}>
          {(formic!.values.areasConfig || []).map((area: AnyObject, index: number) => (
            <TreeItem itemId={area.areaName} key={area.areaName} label={area.areaName}>
              {area?.widgetConfig?.type && (
                <TreeItem
                  onClick={() =>
                    handleTreeSelect(
                      `areasConfig.${index}.widgetConfig`,
                      'WIDGET',
                      area.widgetConfig.type,
                    )
                  }
                  itemId={`areasConfig.${index}.widgetConfig`}
                  key={`areasConfig.${index}.widgetConfig`}
                  label={
                    `${(WidgetsTranslate as any)[area.widgetConfig.type]}` +
                    `${area?.widgetConfig?.options?.fieldName?.value ? `: ${area.widgetConfig.options.fieldName.value}` : ''}`
                  }
                />
              )}
            </TreeItem>
          ))}
        </SimpleTreeView>
      </div>
    </div>
  );
};

export default ViewConstructorFormTree;
