import { AvailableFunctionsType, DataFunction } from '../models/Widget/DataFunction';
import { getFieldByFullPath } from '../components/Widgets/helpers/formikHelpers';
import { AnyObject } from '../models/AnyObject';

export const FUNCTIONS_MAP: Record<AvailableFunctionsType, (...args: Array<any>) => any> = {
  GET_BY_FIELD_NAME: (business_object: AnyObject, ...fields) => {
    return getFieldByFullPath(business_object, fields.join('.'));
  },
  UPPERCASE: (value: any) => {
    if (typeof value === 'string') {
      return value.toUpperCase();
    }
    return value;
  },
  CONCAT: (value: any, ...args: any) => {
    if (args.length > 0) {
      if (value === args[0]) {
        const newArgs = args.slice(1);
        return [value, ...newArgs].join(' ');
      } else {
        return [value, ...args].join(' ');
      }
    }
    return value;
  },
  LOWERCASE: (value: any) => {
    if (typeof value === 'string') {
      return value.toLowerCase();
    }
    return value;
  },
  SLICE: (value: string, ...args: Array<number>) => {
    if (args.length === 1) {
      const index = args[0];
      if (index >= 0 && index < value.length) {
        return value.slice(0, index);
      }
    } else if (args.length === 2) {
      const start = args[0];
      const end = args[1];
      if (start >= 0 && start < value.length && end > start && end <= value.length) {
        return value.slice(start, end);
      }
    }
    return value;
  },
};

export const processValue = (value: any, functions: Array<DataFunction>, logger?: boolean): any => {
  if (!functions?.length) {
    return value;
  }

  const firstFunction = functions[0];
  if (!firstFunction || !firstFunction.args || firstFunction.args.length === 0) {
    return value;
  }

  const result = functions.reduce((resultValue: any, functionConfig: DataFunction) => {
    const dataFunction = FUNCTIONS_MAP[functionConfig.type];

    const argsValues = functionConfig.args.map((arg) => {
      // какая-то странная вещь
      // if (arg.type === 'FIELD') {
      //   return value[arg.value] || arg.value;
      // }
      return arg.value;
    });

    return dataFunction(resultValue, ...argsValues);
  }, value);

  return result;
};
