import React, { FC, useContext } from 'react';
import { GridParameterInput } from './GridParameterInput';
import Button from '@mui/material/Button';
import { DynamicPageViewConfig } from '../../../models/Page/DynamicPageViewConfig';
import ConstructorFormikContext from '../ViewConstructorWidget/contexts/ConstructorFormikContext';

interface GridTrackProps {
  fieldName: keyof Omit<DynamicPageViewConfig, 'areasConfig' | 'gridTemplate'>;
}

export const GridTrack: FC<GridTrackProps> = (props) => {
  const formic = useContext(ConstructorFormikContext);

  const { fieldName } = props;

  const arrayValues = formic!.values[fieldName].split(' ');

  const handleValueChange = (index: number, newValue: string) => {
    const updatedArray = [...arrayValues];
    updatedArray[index] = newValue; // Update the specific index
    formic!.setFieldValue(fieldName, updatedArray.join(' '));
  };

  const handleDeleteValue = (index: number) => {
    const updatedArray = arrayValues.filter((_: any, i: any) => i !== index); // Remove the value at the specified index
    formic!.setFieldValue(fieldName, updatedArray.join(' '));
  };

  const handleAddValue = () => {
    const updatedArray = [...arrayValues, '1fr']; // Add a default value (e.g., '1fr')
    formic!.setFieldValue(fieldName, updatedArray.join(' '));
  };

  return (
    <>
      <div>
        <Button
          onClick={handleAddValue}
          style={{ marginTop: '10px' }}
          variant="contained"
          color="primary"
        >
          +
        </Button>
      </div>

      {formic &&
        arrayValues.map((arrayValue: string, index: number, array: Array<string>) => (
          <GridParameterInput
            value={arrayValue}
            index={index}
            key={index}
            onChange={(newValue: string) => handleValueChange(index, newValue)}
            onDelete={handleDeleteValue}
            array={array}
          />
        ))}
    </>
  );
};
