import React, { Dispatch, SetStateAction } from 'react';
import style from './HeaderComponent.module.scss';
import logo from '../../assets/img/brand/tAvrida.png';
import MenuIcon from '@mui/icons-material/Menu';
import DropdownMenu from '../DropdownMenu/DropdownMenuComponent';

export interface HeaderComponentProps {
  changeFoldStatus: Dispatch<SetStateAction<boolean>>;
}

const HeaderComponent = (props: HeaderComponentProps) => {
  const foldStatusChangeHandler = () => {
    props.changeFoldStatus((prev) => !prev);
  };

  return (
    <div className={style.HeaderComponent + ' row row-align-center'}>
      <div>
        <img
          onClick={foldStatusChangeHandler}
          src={logo}
          alt="PBS"
          className={style.logo + ' mr15'}
        />
        <MenuIcon onClick={foldStatusChangeHandler} className={style.menuToggle + ' cp'}></MenuIcon>
      </div>

      <div className="cp">
        <DropdownMenu />
      </div>
    </div>
  );
};

export default HeaderComponent;
