import React from 'react';
import { WidgetTypesEnum } from '../../../../models/Widget/WidgetTypes.enum';
import { WidgetsTranslate } from './WidgetsTranslate';
import ConstructorWidgetListItem from './ConstructorWidgetListItem';
import style from './WidgetLst.module.css';

// Добавлять по мере реализации
const implementedWidgets: Array<WidgetTypesEnum> = [
  WidgetTypesEnum.LABEL,
  WidgetTypesEnum.INPUT_STRING,
  WidgetTypesEnum.TEXTAREA,
  WidgetTypesEnum.SELECT,
  WidgetTypesEnum.INPUT_BOOLEAN,
  WidgetTypesEnum.INPUT_DATE,
  WidgetTypesEnum.INPUT_JSON,
  WidgetTypesEnum.BLANK,
];

const ViewConstructorWidgetList = () => {
  return (
    <div>
      <h1 style={{ marginBottom: '15px' }}>Виджеты</h1>
      <div className={style.WidgetListContainer}>
        {implementedWidgets.map((widgetType) => (
          <ConstructorWidgetListItem
            title={(WidgetsTranslate as any)[widgetType]}
            type={widgetType}
            key={widgetType}
          />
        ))}
      </div>
    </div>
  );
};

export default ViewConstructorWidgetList;
