import React, { FC, useContext } from 'react';
import { ViewConstructorWidgetFormProps } from '../ViewConstructorForm';
import ConstructorFormikContext from '../../contexts/ConstructorFormikContext';
import { getFieldByFullPath } from '../../../helpers/formikHelpers';
import { InputLabel, TextField, Select, MenuItem } from '@mui/material';
import ConstructorFieldNamesContext from '../../contexts/ConstructorFieldNamesContext';
import formStyles from '../ViewConstructorForms.module.scss';

const InputBooleanConstructorForm: FC<ViewConstructorWidgetFormProps> = ({ areaPath }) => {
  const formik = useContext(ConstructorFormikContext);
  const fieldNames = useContext(ConstructorFieldNamesContext);

  return (
    <div className={formStyles.FormFieldsWrapper}>
      <div>
        <InputLabel>Имя поля</InputLabel>
        <Select
          size="small"
          style={{ width: 220 }}
          id={`${areaPath}.options.fieldName.value`}
          name={`${areaPath}.options.fieldName.value`}
          value={getFieldByFullPath(formik!.values, `${areaPath}.options.fieldName.value`)}
          onChange={formik!.handleChange}
          onBlur={formik!.handleBlur}
        >
          {fieldNames.map((fieldName) => (
            <MenuItem key={fieldName} value={fieldName}>
              {fieldName}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div>
        <InputLabel>Подпись</InputLabel>
        <TextField
          size="small"
          style={{ width: '100%' }}
          id={`${areaPath}.options.label.value`}
          name={`${areaPath}.options.label.value`}
          value={getFieldByFullPath(formik!.values, `${areaPath}.options.label.value`)}
          onChange={formik!.handleChange}
          onBlur={formik!.handleBlur}
          type={'text'}
        />
      </div>
    </div>
  );
};

export default InputBooleanConstructorForm;
