import React, { FC, useContext, useEffect, useState } from 'react';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { DataFunction } from '../../../models/Widget/DataFunction';
import GridTemplateComponent from '../../GridTemplate/GridTemplate.component';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { initialGridConfig } from '../../../mock/InitialGridConfig';
import { pageConfigToGridParams } from '../../../utils/pageConfigToGridPrams';
import { ModuleRouteInfo } from '../../../redux/models/state.model';
import { useParams } from 'react-router-dom';
import { getDataInstance } from '../../../api/resource';
import { WidgetData } from '../../../models/Widget/WidgetData';
import PageDataContext from '../../../contexts/PageDataContext';
import QueryParamsContext from '../../../contexts/QueryParamsContext';
import useConfigureFormik from '../../../hooks/useConfigureFormik';
import FormikContext from '../../../contexts/FormikContext';
import style from './FormInnerGrid.module.scss';
import { InputLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import DeleteIcon from '@mui/icons-material/Delete';
import { cloneDeep } from 'lodash';
import { useAppDispatch } from '../../../redux/store';
import { setFormValue } from '../../../redux/reducers/form-builder-reducer';
import { GetDataInstanceParams } from '../../../models/GetDataInstanceParams';
import FormContext, { FormContextType } from '../../../contexts/FormContextType';
import CurrentActionContext from '../../../contexts/CurrentActionContext';
import { IsLoadAction } from '../../../utils/isLoadAction';
import FormOptionsConfigContext from '../../../contexts/FormOptionsConfigContext';
import { DynamicPageViewConfig } from '../../../models/Page/DynamicPageViewConfig';

export interface FormInnerGridOptions {
  [key: string]: PbsDynamicWidgetValue | any;

  isRepeatable: PbsDynamicWidgetValue<boolean>;
  isRequired: PbsDynamicWidgetValue<boolean>;
  innerGridConfig: PbsDynamicWidgetValue<DynamicPageViewConfig>;
  fieldName: PbsDynamicWidgetValue<string>;
  disabled: PbsDynamicWidgetValue<boolean>;
  label: PbsDynamicWidgetValue<string>;
  dataSource: PbsDynamicWidgetValue<Array<DataFunction>>;
}

const FormInnerGrid: FC<WidgetProps<FormInnerGridOptions>> = (props) => {
  const dispatch = useAppDispatch();

  const [label, isRequired, fieldName, innerGridConfig, disabled] = valueFromConfig(
    props.config?.options,
    'label',
    'isRequired',
    'fieldName',
    'innerGridConfig',
    'disabled',
  );

  const formicFromContext = useContext(FormikContext);
  const formContext = useContext(FormContext);
  const queryParam = useContext(QueryParamsContext);
  const currentAction = useContext(CurrentActionContext);
  const pageDataContext = useContext(PageDataContext);

  const [gridConfig, setGridConfig] = useState(initialGridConfig);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [pageData, setPageData] = useState<WidgetData | null>(null);
  const [fullPath, setFullPath] = useState<string>(props.config.options.formPath || '');
  const [formOptions, setFormOptions] = useState<FormInnerGridOptions>(props.config.options);
  const [isRepeatable] = useState<boolean>(props.config.options?.isRepeatable?.value || false);
  const [innerGridState, setInnerGridState] = useState<DynamicPageViewConfig>(innerGridConfig);
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());

  const formikConfig = useConfigureFormik(formOptions);
  const { moduleKey, id, type, configPath } = useParams() as unknown as ModuleRouteInfo;

  useEffect(() => {
    setFormOptions(props.config.options);
  }, [props.config.options]);

  const loadPageData = (queryParam: GetDataInstanceParams, controller: AbortController) => {
    setIsDataLoaded(true);
    getDataInstance(moduleKey, queryParam, controller.signal)
      .then((data) => {
        if (data?.data) {
          if (!formicFromContext) {
            Object.keys(data.data).forEach((key: string) => {
              formikConfig.formik.setFieldValue(key, cloneDeep(data.data[key]));
            });
          }
          setPageData(data);
        } else {
          setPageData(null);
        }
      })
      .catch((e) => {
        console.error(e);
        setIsDataLoaded(false);
        setPageData(null);
      });
  };

  useEffect(() => {
    if (IsLoadAction(currentAction.actionType)) {
      loadPageData(formContext.context as FormContextType, abortController);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.context]);

  useEffect(() => {
    const { formPath } = props.config;
    setFullPath(`${formPath ? `${formPath}.` : ''}${fieldName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config.formPath]);

  useEffect(() => {
    // TODO хорошо подумать и переделать это свинство, возможно уйти от стейта
    if (fieldName === 'main') {
      dispatch(setFormValue(formikConfig.formik.values));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikConfig.formik.values, fieldName]);

  useEffect(() => {
    // TODO ужас, очень много лишних запросов
    if (
      queryParam &&
      !formContext.context?.configName &&
      !formicFromContext &&
      IsLoadAction(currentAction.actionType)
    ) {
      // loadPageData(queryParam as FormContextType, abortController);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  useEffect(() => {
    const queryParams = { configName: configPath, id, type };
    if (isDataLoaded || fieldName !== 'main') {
      return;
    }
    // if (queryParams.configName && queryParams.id && queryParams.type) {
    //     getDataInstance(moduleKey, queryParams, controller.signal)
    //         .then(data => {
    //             if (data) {
    //                 setPageData(data);
    //             } else {
    //                 setPageData(null);
    //             }
    //         })
    //         .catch(() => {
    //             setPageData(null);
    //         });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleKey, id, type, configPath]);

  useEffect(() => {
    const gridConfig = formOptions?.innerGridConfig?.value;
    setInnerGridState(gridConfig || innerGridConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOptions]);

  useEffect(() => {
    if (innerGridState) {
      setGridConfig(pageConfigToGridParams(innerGridState));
    }
  }, [innerGridState]);

  const addRepeatableItem = () => {
    const configValue = cloneDeep(
      getFieldByFullPath(formicFromContext?.formikConfig || {}, fullPath),
    ) as Array<any>;

    const value = cloneDeep(
      getFieldByFullPath(formicFromContext?.formik?.values || {}, fullPath),
    ) as Array<any>;

    if (Array.isArray(configValue)) {
      const newValue = Array.isArray(value) ? [...value, configValue[0]] : configValue;
      formicFromContext!.formik!.setFieldValue(fullPath, newValue);
    }
  };

  const deleteRepeatableItem = (index: number) => {
    const value = cloneDeep(
      getFieldByFullPath(formicFromContext?.formik?.values, fullPath),
    ) as Array<any>;

    if (Array.isArray(value)) {
      value.splice(index, 1);
      formicFromContext!.formik!.setFieldValue(fullPath, value);
    }
  };

  return (
    <>
      {formicFromContext ? (
        !isRepeatable ? (
          <div style={{ height: '100%', width: '100%' }}>
            <PageDataContext.Provider value={pageData || pageDataContext}>
              <GridTemplateComponent
                gridTemplateAreas={gridConfig.gridTemplateAreas}
                gridTemplateColumns={gridConfig.gridTemplateColumns}
                gridTemplateRows={gridConfig.gridTemplateRows}
                gridTemplateColumnGap={gridConfig.gridTemplateColumnGap}
                gridTemplateRowGap={gridConfig.gridTemplateRowGap}
                areasConfig={innerGridState.areasConfig || []}
                viewType={innerGridState.viewType}
                formPath={fullPath}
              />
            </PageDataContext.Provider>
          </div>
        ) : (
          <div style={{ height: '100%', width: '100%' }}>
            <div className={style.RepeatableGridTitle}>
              <InputLabel required={isRequired}>{label}</InputLabel>
              {!disabled && <AddIcon className={style.Icon} onClick={addRepeatableItem} />}
            </div>
            {(
              (getFieldByFullPath(formicFromContext?.formik?.values, fullPath) as Array<any>) || []
            ).map((item, index) => {
              return (
                <div className={style.RepeatableItemWrapper} key={index}>
                  <div className={style.RepeatableItemContent}>
                    <PageDataContext.Provider value={pageData || pageDataContext}>
                      <GridTemplateComponent
                        gridTemplateAreas={gridConfig.gridTemplateAreas}
                        gridTemplateColumns={gridConfig.gridTemplateColumns}
                        gridTemplateRows={gridConfig.gridTemplateRows}
                        gridTemplateColumnGap={gridConfig.gridTemplateColumnGap}
                        gridTemplateRowGap={gridConfig.gridTemplateRowGap}
                        areasConfig={innerGridState.areasConfig || []}
                        viewType={innerGridState.viewType}
                        formPath={`${fullPath}.${index}`}
                      />
                    </PageDataContext.Provider>
                  </div>
                  {!disabled && (
                    <div>
                      <DeleteIcon
                        className={style.Icon}
                        onClick={() => deleteRepeatableItem(index)}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )
      ) : (
        <FormikContext.Provider value={formikConfig}>
          <FormOptionsConfigContext.Provider value={{ formOptions, setFormOptions }}>
            <div style={{ height: '100%', width: '98%' }}>
              {pageData ? (
                <PageDataContext.Provider value={pageData}>
                  <GridTemplateComponent
                    gridTemplateAreas={gridConfig.gridTemplateAreas}
                    gridTemplateColumns={gridConfig.gridTemplateColumns}
                    gridTemplateRows={gridConfig.gridTemplateRows}
                    gridTemplateColumnGap={gridConfig.gridTemplateColumnGap}
                    gridTemplateRowGap={gridConfig.gridTemplateRowGap}
                    areasConfig={innerGridState.areasConfig || []}
                    viewType={innerGridState.viewType}
                    formPath={fullPath}
                  />
                </PageDataContext.Provider>
              ) : (
                <GridTemplateComponent
                  gridTemplateAreas={gridConfig.gridTemplateAreas}
                  gridTemplateColumns={gridConfig.gridTemplateColumns}
                  gridTemplateRows={gridConfig.gridTemplateRows}
                  gridTemplateColumnGap={gridConfig.gridTemplateColumnGap}
                  gridTemplateRowGap={gridConfig.gridTemplateRowGap}
                  areasConfig={innerGridState.areasConfig || []}
                  viewType={innerGridState.viewType}
                  formPath={fullPath}
                />
              )}
            </div>
          </FormOptionsConfigContext.Provider>
        </FormikContext.Provider>
      )}
    </>
  );
};

export default FormInnerGrid;
