import { WidgetTypesEnum } from '../../../../models/Widget/WidgetTypes.enum';

// Расширять по мере необходимости
export const WidgetsTranslate = {
  [WidgetTypesEnum.LABEL]: 'Текст',
  [WidgetTypesEnum.INPUT_STRING]: 'Поле ввода',
  [WidgetTypesEnum.INPUT_NUMBER]: 'Поле ввода',
  [WidgetTypesEnum.INPUT_DATE]: 'Ввод даты',
  [WidgetTypesEnum.INPUT_BOOLEAN]: 'Чекбокс',
  [WidgetTypesEnum.SELECT]: 'Селект',
  [WidgetTypesEnum.SELECT_MULTIPLE]: 'Селект',
  [WidgetTypesEnum.TEXTAREA]: 'Текстария',
  [WidgetTypesEnum.TABLE]: 'Таблица',
  [WidgetTypesEnum.TREE_LIST_VIEW]: 'Дерево',
  [WidgetTypesEnum.FORMS_INNER_GRID]: 'Форма',
  [WidgetTypesEnum.PAGE_VIEW]: 'Страница',
  [WidgetTypesEnum.BLANK]: 'Пустой виджет',
  [WidgetTypesEnum.INPUT_JSON]: 'Ввод кода',
  [WidgetTypesEnum.CODE_EDITOR_GROOVY]: 'Ввод кода',
};
