import { PrevContext } from '../models/state.model';
import { createSlice } from '@reduxjs/toolkit';

const initialState: PrevContext = {
  needSetContext: false,
  context: null,
};

const PrevContextSlice = createSlice({
  name: 'PrevContext',
  initialState,
  reducers: {
    setPrevContext(state: PrevContext, action) {
      return action.payload;
    },
  },
});

export const { setPrevContext } = PrevContextSlice.actions;

const PrevContextReducer = PrevContextSlice.reducer;

export default PrevContextReducer;
