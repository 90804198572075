import React, { FC } from 'react';
import { WidgetTypesEnum } from '../../../../models/Widget/WidgetTypes.enum';
import { WidgetsTranslate } from '../VidgetList/WidgetsTranslate';
import LabelConstructorForm from './Forms/LabelConstructorForm';
import TextareaConstructorForm from './Forms/TextareaConstructorForm';
import InputStringConstructorForm from './Forms/InputStringConstructorForm';
import SelectConstructorForm from './Forms/SelectConstructorForm';
import InputBooleanConstructorForm from './Forms/InputBooleanConstructorForm';
import InputDateConstructorForm from './Forms/InputDateConstructorForm';
import InputJSONConstructorForm from './Forms/InputJSONConstructorForm';

// Пока подразумевается, что для всех форм будут одинаковые пропсы
export interface ViewConstructorWidgetFormProps {
  areaPath: string;
}

interface ViewConstructorFormProps {
  widgetType: WidgetTypesEnum;
  areaPath: string;
}

const ViewConstructorForm: FC<ViewConstructorFormProps> = ({ widgetType, areaPath }) => {
  return (
    <div>
      <h1 style={{ marginBottom: '15px' }}>{(WidgetsTranslate as any)[widgetType]}</h1>
      {(() => {
        switch (widgetType) {
          case WidgetTypesEnum.LABEL:
            return <LabelConstructorForm areaPath={areaPath} />;
          case WidgetTypesEnum.TEXTAREA:
            return <TextareaConstructorForm areaPath={areaPath} />;
          case WidgetTypesEnum.SELECT:
          case WidgetTypesEnum.SELECT_MULTIPLE:
            return <SelectConstructorForm areaPath={areaPath} />;
          case WidgetTypesEnum.INPUT_NUMBER:
          case WidgetTypesEnum.INPUT_STRING:
            return <InputStringConstructorForm areaPath={areaPath} />;
          case WidgetTypesEnum.INPUT_BOOLEAN:
            return <InputBooleanConstructorForm areaPath={areaPath} />;
          case WidgetTypesEnum.INPUT_DATE:
            return <InputDateConstructorForm areaPath={areaPath} />;
          case WidgetTypesEnum.CODE_EDITOR_GROOVY:
          case WidgetTypesEnum.INPUT_JSON:
            return <InputJSONConstructorForm areaPath={areaPath} />;
          case WidgetTypesEnum.BLANK:
            return <h4>Нет настроек</h4>;
          case WidgetTypesEnum.NONE:
            return <h4>Форма не выбрана</h4>;
          default:
            return (
              <>
                <h5>Форма не реализована</h5>
              </>
            );
        }
      })()}
    </div>
  );
};

export default ViewConstructorForm;
