import React, { useEffect, useState } from 'react';
import {
  updateCurrentForm,
  updateFocusComponent,
} from '../../../../redux/reducers/sqlConstructorReducer';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { StateModel } from '../../../../redux/models/state.model';
import style from '../SqlBlocks.module.css';
import Button from '@mui/material/Button';
import { SqlBuilderForms } from '../../../../utils/sqlBuilderForms';
import { updateFormValues } from '../../../../redux/reducers/sqlStateServiceReducer';
import { getOperationByCode } from '../../../../utils/OperationTypeByValue';

interface Props {
  formState?: any;
  formKey: string;
}

const SqlBlockWhere: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useDispatch();
  const componentId = useState(uuidv4())[0];
  const focusedElementId = useSelector(
    (state: StateModel) => state.sqlConstructor.focusedElementId,
  );

  const [expression, setExpression] = useState<Array<any>>([]);

  useEffect(() => {
    if (formState.expression?.length) setExpression([...formState.expression]);
  }, [formState]);

  const onRowClick = ($event: any) => {
    $event.stopPropagation();
    dispatch(updateFocusComponent(componentId));
    dispatch(
      updateCurrentForm({
        componentName: 'WhereComponent',
        formState,
        formKey,
      }),
    );
  };

  const openEmptyExpression = () => {
    const newCondition = SqlBuilderForms.buildExpressionForm();

    const updatedFormState = { ...formState };
    if (!updatedFormState.expression) {
      updatedFormState.expression = [];
    }

    const clonedExpression = [...updatedFormState.expression];
    clonedExpression.push(newCondition);

    updatedFormState.expression = clonedExpression;
    dispatch(updateFormValues({ values: updatedFormState, formKey }));
  };

  const operationTitle = getOperationByCode;

  return (
    <>
      {!['condition', 'conditions'].includes(formState?.jacksonType) && (
        <div
          className={`${style.EditBlock} ${style.Empty} ${componentId === focusedElementId ? style.Focused : ''}`}
          onClick={(e) => onRowClick(e)}
        ></div>
      )}

      {formState?.jacksonType === 'condition' && (
        <div
          className={`${style.EditBlock} ${style.Empty} ${componentId === focusedElementId ? style.Focused : ''}`}
          onClick={(e) => onRowClick(e)}
        >
          {formState.leftSource &&
            formState.leftParam &&
            `${formState.leftSource.alias || formState.leftSource.name}${formState.leftSource.alias || formState.leftSource.name ? '.' : ''}${formState.leftParam?.alias || formState.leftParam.name}`}

          {formState.operation && operationTitle ? operationTitle(formState.operation) : ''}

          {formState.rightSource && formState.rightParam && (
            <>
              {formState.rightSource.alias || formState.rightSource.name}
              {formState.rightSource.alias || formState.rightSource.name ? '.' : ''}
              {formState.rightParam?.alias || formState.rightParam.name}
            </>
          )}

          {formState.rightParam && formState.rightParam.id && formState.rightParam.value && (
            <>
              {formState.rightParam.id}
              {formState.rightParam.id && formState.rightParam.value && '.'}
              {formState.rightParam.value}
            </>
          )}
        </div>
      )}

      {formState?.jacksonType === 'conditions' && (
        <div
          className={`${style.EditBlock} ${style.Empty} ${componentId === focusedElementId ? style.Focused : ''} ${style.Inline}`}
          onClick={(e) => onRowClick(e)}
        >
          <Button onClick={openEmptyExpression}>+</Button>

          {expression.map((expressionItem, index) => (
            <React.Fragment key={`${formKey}-${index}`}>
              <SqlBlockWhere
                formKey={formKey.concat(`.expression.${index}`)}
                formState={expressionItem}
              />
              {index !== expression.length - 1 && (
                <React.Fragment>{formState.conditionType}</React.Fragment>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default SqlBlockWhere;
