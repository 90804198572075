import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {};

const FormConfigSlice = createSlice({
  name: 'FormConfigState',
  initialState,
  reducers: {
    setFromConfigState(state: any, action) {
      return action.payload;
    },
  },
});

export const { setFromConfigState } = FormConfigSlice.actions;

const FormConfigReducer = FormConfigSlice.reducer;

export default FormConfigReducer;
