import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRequiredFields } from '../../../redux/reducers/form-builder-reducer';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { Box, InputLabel, TextField, Typography } from '@mui/material';
import style from './InputStringWidget.module.css';
import { WidgetTypesEnum } from '../../../models/Widget/WidgetTypes.enum';
import FormikContext from '../../../contexts/FormikContext';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import { useSetDefaultWidgetValue } from '../../../hooks/useSetDefaultWidgetValue';
import { ExecutePageActionParams } from '../../../models/GetWidgetDynamicDataParams';
import { executePageAction } from '../../../api/resource';
import { useParams } from 'react-router-dom';
import { ModuleRouteInfo } from '../../../redux/models/state.model';

export interface InputWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;

  label: PbsDynamicWidgetValue<string>;
  isRequired: PbsDynamicWidgetValue<boolean>;
  fieldName: PbsDynamicWidgetValue<string>;
}

const InputStringWidget: FC<WidgetProps<InputWidgetOptions>> = (props) => {
  const dispatch = useDispatch();

  const formicFromContext = useContext(FormikContext);

  const inputRef = useRef<any>(null);

  const [label, isRequired, fieldName, height, width, inputWidth, labelWidth, smartWidget] =
    valueFromConfig(
      props.config?.options,
      'label',
      'isRequired',
      'fieldName',
      'height',
      'width',
      'inputWidth',
      'labelWidth',
      'smartWidget',
    );

  const [smartState, setSmartState] = useState<boolean>(false);
  const [selfSmart, setSelfSmart] = useState<boolean>(false);
  const [value, setValue] = useState<string | number>(
    getFieldByFullPath(
      formicFromContext?.formik?.values || {},
      `${props.config.formPath}.${fieldName}`,
    ),
  );
  const [smartSelfValue, setSmartSelfValue] = useState<number | string>('');

  const { moduleKey, id, type, configPath } = useParams() as unknown as ModuleRouteInfo;

  useSetDefaultWidgetValue(`${props.config.formPath}.${fieldName}`);

  useEffect(() => {
    if (formicFromContext) {
      setValue(
        getFieldByFullPath(
          formicFromContext!.formik.values || {},
          `${props.config.formPath}.${fieldName}`,
        ),
      );
    }
  }, [
    getFieldByFullPath(
      formicFromContext?.formik?.values || {},
      `${props.config.formPath}.${fieldName}`,
    ),
  ]);

  useEffect(() => {
    if (smartWidget) {
      setSelfSmart(smartWidget.changeEditStateActionEvent === 'ON_CLICK');
    }
  }, [smartWidget]);

  useEffect(() => {
    if (smartState) {
      setSmartSelfValue(value);
    }
  }, [smartState]);

  useEffect(() => {
    if (isRequired) {
      dispatch(setRequiredFields(fieldName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config]);

  const onBlur = () => {
    if (!selfSmart) {
      return;
    }

    const params: ExecutePageActionParams = {
      configName: configPath,
      id,
      type,
      actionName: smartWidget.submitActionName,
    };

    setSmartState(false);
    executePageAction(moduleKey, params, { main: { [fieldName]: smartSelfValue } }).then(
      (response) => {
        if (response) {
          setValue(smartSelfValue);
        }
        setSmartSelfValue('');
      },
    );
  };

  const smartLabelClick = () => {
    if (selfSmart) {
      setSmartState(true);

      setTimeout(() => {
        inputRef?.current?.querySelector('input')?.focus();
      }, 100);
    }
  };

  return (
    <div className={style.inputContainer} style={{ width, height }}>
      <Box display="flex" alignItems="center" style={{ width: labelWidth }}>
        <InputLabel>{label}</InputLabel>
        {isRequired && (
          <Typography variant="inherit" color="error">
            *
          </Typography>
        )}
      </Box>
      {!smartWidget || (smartWidget && smartState) ? (
        <>
          {formicFromContext?.formik ? (
            <TextField
              ref={inputRef}
              size="small"
              style={{ width: inputWidth }}
              className={style.inputField}
              id={`${props.config.formPath}.${fieldName}`}
              name={`${props.config.formPath}.${fieldName}`}
              value={smartSelfValue || value}
              onChange={(e) => {
                if (selfSmart) {
                  setSmartSelfValue(e.target.value);
                } else {
                  formicFromContext!.formik.handleChange(e);
                }
              }}
              onBlur={(e) => {
                onBlur();
                formicFromContext!.formik.handleBlur(e);
              }}
              error={
                formicFromContext!.formik.touched[fieldName] &&
                Boolean(formicFromContext!.formik.errors[fieldName])
              }
              type={props.config.type === WidgetTypesEnum.INPUT_NUMBER ? 'number' : 'text'}
              // helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            />
          ) : (
            <TextField
              size="small"
              style={{ width: inputWidth }}
              className={style.inputField}
              type={props.config.type === WidgetTypesEnum.INPUT_NUMBER ? 'number' : 'text'}
            />
          )}
        </>
      ) : (
        <div
          style={{
            cursor: selfSmart ? 'pointer' : undefined,
          }}
          onClick={smartLabelClick}
        >
          {smartSelfValue ||
            getFieldByFullPath(
              formicFromContext?.formik?.values || {},
              `${props.config.formPath}.${fieldName}`,
            )}
        </div>
      )}
    </div>
  );
};

export default InputStringWidget;
