import React, { FC, useContext } from 'react';
import { ViewConstructorWidgetFormProps } from '../ViewConstructorForm';
import ConstructorFormikContext from '../../contexts/ConstructorFormikContext';
import { getFieldByFullPath } from '../../../helpers/formikHelpers';
import {
  InputLabel,
  TextField,
  Accordion,
  AccordionSummary,
  Select,
  MenuItem,
} from '@mui/material';
import DataSourceConstructor from './DataSourceConstructor/DataSourceConstructor';
import ConstructorFieldNamesContext from '../../contexts/ConstructorFieldNamesContext';
import formStyles from '../ViewConstructorForms.module.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { GridParameterInput } from '../../../ConstructorProperties/GridParameterInput';

const fontSizeValues = ['normal', 'lighter', 'bold', '300', '400', '500', '600', '700', '800'];
// const labelType = ['STATIC', 'DYNAMIC'];

const LabelConstructorForm: FC<ViewConstructorWidgetFormProps> = ({ areaPath }) => {
  const formik = useContext(ConstructorFormikContext);
  const fieldNames = useContext(ConstructorFieldNamesContext);

  return (
    <div className={formStyles.FormFieldsWrapper}>
      <div>
        <InputLabel>Имя поля</InputLabel>
        <Select
          size="small"
          style={{ width: 220 }}
          id={`${areaPath}.options.fieldName.value`}
          name={`${areaPath}.options.fieldName.value`}
          value={getFieldByFullPath(formik!.values, `${areaPath}.options.fieldName.value`)}
          onChange={formik!.handleChange}
          onBlur={formik!.handleBlur}
        >
          {fieldNames.map((fieldName) => (
            <MenuItem key={fieldName} value={fieldName}>
              {fieldName}
            </MenuItem>
          ))}
        </Select>
      </div>

      {/*Скорее всего - ненужный параметр*/}
      {/*<div>*/}
      {/*  <InputLabel>Тип</InputLabel>*/}
      {/*  <Select*/}
      {/*    size="small"*/}
      {/*    style={{ width: 220 }}*/}
      {/*    id={`${areaPath}.options.labelType.value`}*/}
      {/*    name={`${areaPath}.options.labelType.value`}*/}
      {/*    value={getFieldByFullPath(formik!.values, `${areaPath}.options.labelType.value`)}*/}
      {/*    onChange={formik!.handleChange}*/}
      {/*    onBlur={formik!.handleBlur}*/}
      {/*    error={formik!.touched.name && Boolean(formik!.errors.name)}*/}
      {/*  >*/}
      {/*    {labelType.map((type: any, index) => (*/}
      {/*      <MenuItem key={index} value={type}>*/}
      {/*        {type}*/}
      {/*      </MenuItem>*/}
      {/*    ))}*/}
      {/*  </Select>*/}
      {/*</div>*/}

      <div>
        <InputLabel>Значение</InputLabel>
        <TextField
          size="small"
          style={{ width: '100%' }}
          id={`${areaPath}.value`}
          name={`${areaPath}.value`}
          value={getFieldByFullPath(formik!.values, `${areaPath}.value`)}
          onChange={formik!.handleChange}
          onBlur={formik!.handleBlur}
          type={'text'}
        />
      </div>

      <DataSourceConstructor
        style={{ width: '90%' }}
        formPath={`${areaPath}.options.dataSource.value`}
      />

      <div className={formStyles.WidgetStyleAccordionWrapper}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id={`Textarea-style`}
          >
            <InputLabel style={{ borderBottom: 'none', padding: 0, width: '100%' }}>
              Стлили
            </InputLabel>
          </AccordionSummary>

          <div className={formStyles.WidgetStyleWrapper}>
            <div>
              <InputLabel>Ширина</InputLabel>
              <GridParameterInput
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.width.value`)}
                onChange={(newValue: string) => {
                  formik!.setFieldValue(`${areaPath}.options.width.value`, newValue);
                }}
              />
            </div>

            <div>
              <InputLabel>Высота</InputLabel>
              <GridParameterInput
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.height.value`)}
                onChange={(newValue: string) => {
                  formik!.setFieldValue(`${areaPath}.options.height.value`, newValue);
                }}
              />
            </div>

            <div>
              <InputLabel>Размер шрифта</InputLabel>
              <TextField
                size="small"
                style={{ width: 'auto' }}
                id={`${areaPath}.options.fontSize.value`}
                name={`${areaPath}.options.fontSize.value`}
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.fontSize.value`)}
                onChange={formik!.handleChange}
                onBlur={formik!.handleBlur}
                type={'number'}
              />
            </div>

            <div>
              <InputLabel>Жирность шрифта</InputLabel>
              <Select
                style={{ width: '100%' }}
                id={`${areaPath}.options.fontWeight.value`}
                name={`${areaPath}.options.fontWeight.value`}
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.fontWeight.value`)}
                onChange={formik!.handleChange}
                onBlur={formik!.handleBlur}
                error={formik!.touched.name && Boolean(formik!.errors.name)}
              >
                {fontSizeValues.map((type: any, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default LabelConstructorForm;
