import { SelectableItem } from '../components/Widgets/SelectWidget/models/item';
import { selectOption } from '../components/Widgets/SelectWidget/SelectWidget';

export const SelectableItemsMapper = (array: Array<SelectableItem>): Array<selectOption> => {
  return (array || []).map((item: SelectableItem) => ({
    value: item.id.id,
    title: item.title,
    key: item.id.id,
  }));
};
