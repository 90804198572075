import React, { FC, useContext, useEffect, useState } from 'react';
import style from './GridView.module.scss';
import GridArrayCellForm, { GridCellPosition } from './GridArrayCellForm/GridArrayCellForm';
import GridArea from './GridArea/GridArea';
import ConstructorFormikContext from './contexts/ConstructorFormikContext';

interface GridViewProps {
  // загатовка на вложенные формы, которые будут когда-то потом
  path: string;
}

export const GridView: FC<GridViewProps> = ({ path }) => {
  const formik = useContext(ConstructorFormikContext);

  const [gridValue, setGridValue] = useState<any>(formik!.values);

  const [activeForm, setActiveForm] = useState<boolean>(false);
  const [startCell, setStartCell] = useState<GridCellPosition>(null);
  const [endCell, setEndCell] = useState<GridCellPosition>(null);

  useEffect(() => {
    setGridValue(formik!.values);
  }, [formik!.values]);

  const emptyAreaClick = (rowIndex: number, colIndex: number) => {
    if (!activeForm) {
      setActiveForm(true);
      setStartCell({ rowIndex, colIndex });
    }
    setEndCell({ rowIndex, colIndex });
  };

  const getTemplateAreas = (gridTemplate: Array<string> | undefined) => {
    if (!gridTemplate) {
      return '';
    }

    return gridTemplate.map((row) => `"${row}"`).join(' ');
  };

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <div
        className={style.gridView}
        style={{
          gridTemplateRows: gridValue.rowSize,
          gridTemplateColumns: gridValue.columnSize,
          gridTemplateAreas: getTemplateAreas(formik!.getFieldProps(`gridTemplate`)?.value),
          gridColumnGap: gridValue.columnGap,
          gridRowGap: gridValue.rowGap,
        }}
      >
        {(formik!.getFieldProps(`areasConfig`)?.value || []).map(
          (area: { areaName: string }, index: number) => {
            return <GridArea gridAreaName={area.areaName} key={index} path={path} index={index} />;
          },
        )}
      </div>
      <div
        className={style.gridArrayView}
        style={{
          gridTemplateRows: gridValue.rowSize,
          gridTemplateColumns: gridValue.columnSize,
        }}
      >
        {gridValue.rowSize.split(' ').map((row: any, rowIndex: number) => {
          return gridValue.columnSize.split(' ').map((column: any, colIndex: number) => {
            return (
              <div
                className={style.gridCell}
                onClick={() => emptyAreaClick(rowIndex + 1, colIndex + 1)}
                key={rowIndex + '/' + colIndex}
              >
                row: {rowIndex} col: {colIndex}
              </div>
            );
          });
        })}

        {activeForm && (
          <GridArrayCellForm
            endCell={endCell}
            startCell={startCell}
            onClose={() => {
              setStartCell(null);
              setEndCell(null);
              setActiveForm(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
