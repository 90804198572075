import React, { FC, useContext } from 'react';
import formStyles from '../../ViewConstructorForms.module.scss';
import ConstructorMethodsContext from '../../../contexts/ConstructorMethodsContext';
import ConstructorFormikContext from '../../../contexts/ConstructorFormikContext';
import { Checkbox } from 'antd';
import { getFieldByFullPath } from '../../../../helpers/formikHelpers';
import { InputLabel, MenuItem, Select } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { selectOption } from '../../../../SelectWidget/SelectWidget';

const SmartWidgetOption: FC<{ areaPath: string }> = ({ areaPath }) => {
  const formik = useContext(ConstructorFormikContext);
  const methods = useContext(ConstructorMethodsContext);

  return (
    <>
      <div style={{ width: 220 }}>
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              formik!.setFieldValue(`${areaPath}.options.smartWidget.value`, {
                changeEditStateActionEvent: 'ON_CLICK',
                submitActionName: '',
              });
            } else {
              formik!.setFieldValue(`${areaPath}.options.smartWidget`, null);
            }
          }}
          checked={!!getFieldByFullPath(formik!.values, `${areaPath}.options.smartWidget`)}
        >
          <InputLabel>Умный виджет</InputLabel>
        </Checkbox>
      </div>

      {!!getFieldByFullPath(formik!.values, `${areaPath}.options.smartWidget`) && (
        <div className={formStyles.WidgetStyleAccordionWrapper}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id={`select-smart_widget`}
            >
              <InputLabel style={{ borderBottom: 'none', padding: 0, width: '100%' }}>
                Умный виджет
              </InputLabel>
            </AccordionSummary>

            <div className={formStyles.WidgetStyleWrapper}>
              <div>
                <InputLabel>Событие перехода</InputLabel>
                <Select
                  size="small"
                  style={{ width: 220 }}
                  id={`${areaPath}.options.smartWidget.value.changeEditStateActionEvent`}
                  name={`${areaPath}.options.smartWidget.value.changeEditStateActionEvent`}
                  value={getFieldByFullPath(
                    formik!.values,
                    `${areaPath}.options.smartWidget.value.changeEditStateActionEvent`,
                  )}
                  onChange={formik!.handleChange}
                  onBlur={formik!.handleBlur}
                >
                  <MenuItem key={'ON_CLICK'} value={'ON_CLICK'}>
                    Клик
                  </MenuItem>
                  <MenuItem key={'TODO'} value={'TODO'} disabled={true}>
                    TODO
                  </MenuItem>
                </Select>
              </div>

              <div>
                <InputLabel>Метод</InputLabel>
                <Select
                  size="small"
                  style={{ width: 220 }}
                  id={`${areaPath}.options.smartWidget.value.submitActionName`}
                  name={`${areaPath}.options.smartWidget.value.submitActionName`}
                  value={getFieldByFullPath(
                    formik!.values,
                    `${areaPath}.options.smartWidget.value.submitActionName`,
                  )}
                  onChange={formik!.handleChange}
                  onBlur={formik!.handleBlur}
                >
                  {(methods || []).map((method: selectOption) => (
                    <MenuItem key={method.key} value={method.value}>
                      {method.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default SmartWidgetOption;
