import { useContext, useEffect, useState } from 'react';
import PageDataContext from '../contexts/PageDataContext';
import CurrentActionContext from '../contexts/CurrentActionContext';
import { IsLoadAction } from '../utils/isLoadAction';

const usePageDataContext = (fieldPath: string | undefined) => {
  const pageData = useContext(PageDataContext)?.data;
  const currentAction = useContext(CurrentActionContext);
  const [contextByFieldName, setContextByFieldName] = useState<any>(undefined);

  useEffect(() => {
    const contextUrls: Array<string> = fieldPath?.split('.') || [];

    if (IsLoadAction(currentAction.actionType)) {
      const data = pageData;
      setContextByFieldName(contextUrls.reduce((data, path) => data?.[path], data));
    }
  }, [fieldPath, currentAction]);

  return contextByFieldName;
};

export default usePageDataContext;
