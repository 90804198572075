import React, { FC, useContext, useEffect, useState } from 'react';
import { initialGridConfig } from '../../../mock/InitialGridConfig';
import { ModuleRouteInfo, StateModel } from '../../../redux/models/state.model';
import { useParams } from 'react-router-dom';
import { getDataInstance, loadPageConfig } from '../../../api/resource';
import {
  DynamicPageViewConfig,
  FamilyPageConfig,
} from '../../../models/Page/DynamicPageViewConfig';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import GridTemplateComponent from '../../GridTemplate/GridTemplate.component';
import { pageConfigToGridParams } from '../../../utils/pageConfigToGridPrams';
import { WidgetData } from '../../../models/Widget/WidgetData';
import PageDataContext from '../../../contexts/PageDataContext';
import ConfigNameContext from '../../../contexts/ConfigNameContext';
import RegisterWidgetContext, { RESET_AREA_FORM } from '../../../contexts/RegisterWidgetContext';
import QueryParamsContext from '../../../contexts/QueryParamsContext';
import style from './PageView.module.scss';
import CurrentActionContext from '../../../contexts/CurrentActionContext';
import { IsLoadAction } from '../../../utils/isLoadAction';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import FormOptionsConfigContext from '../../../contexts/FormOptionsConfigContext';
import { AreasConfig } from '../../../models/Page/AreasConfig';
import { cloneDeep } from 'lodash';
import FormikContext from '../../../contexts/FormikContext';
import { useDispatch, useSelector } from 'react-redux';
import { setFromConfigState } from '../../../redux/reducers/form-config-reducer';
import { AnyObject } from '../../../models/AnyObject';

export interface PageViewOptions {
  needsDataPreload: PbsDynamicWidgetValue<boolean>;
  page_key: PbsDynamicWidgetValue<string>;
  areaName: string;
}

const PageView: FC<any> = (props) => {
  // TODO разобраться с props.options.needsDataPreload

  const dispatch = useDispatch();

  const [fieldName] = valueFromConfig(props.config?.options, 'fieldName');

  const { formPath } = props.config;

  const [gridConfig, setGridConfig] = useState(initialGridConfig);
  const [pageData, setPageData] = useState<WidgetData | null>(null);
  const [fullPath, setFullPath] = useState<string>(
    `${formPath ? `${formPath}.` : ''}${fieldName || ''}`,
  );
  const [pageConfig, setPageConfig] = useState<DynamicPageViewConfig>();
  const [controller, setController] = useState<AbortController>(new AbortController());

  const formicFromContext = useContext(FormikContext);
  const registerWidgetContext = useContext(RegisterWidgetContext);
  const currentAction = useContext(CurrentActionContext);
  const formOptions = useContext(FormOptionsConfigContext);
  const queryParam = useContext(QueryParamsContext);

  const pageStateConfig = useSelector((state: StateModel) => state.pageConfig);

  const params: ModuleRouteInfo = useParams() as unknown as ModuleRouteInfo;

  const { moduleKey, id, type, configPath } = params;

  useEffect(() => {
    if (pageStateConfig[fullPath] && fullPath) {
      setPageConfig(pageStateConfig[fullPath]);
    }
  }, [pageStateConfig[fullPath], fullPath]);

  const setPageOptionsToForm = (config: DynamicPageViewConfig) => {
    const [mainForm, ...path] = [...props.config.formPath.split('.'), fieldName];
    const [formFieldName] = valueFromConfig(formOptions!.formOptions, 'fieldName');
    if (formFieldName === mainForm) {
      const newOptions = cloneDeep(formOptions!.formOptions);

      const targetArea = path.reduce((obj, field) => {
        return obj?.innerGridConfig?.value?.areasConfig?.find((area: AreasConfig) => {
          return area.widgetConfig.options.fieldName.value === field;
        });
      }, newOptions);

      if (targetArea) {
        try {
          const configClone = cloneDeep(config);
          targetArea.widgetConfig = {
            ...configClone,
            ...targetArea.widgetConfig,
            options: {
              ...targetArea.widgetConfig.options,
              innerGridConfig: {
                value: {
                  areasConfig: configClone.areasConfig,
                },
              },
            },
          };
          formOptions!.setFormOptions(newOptions);
        } catch (e) {
          console.error('Ошибка при формировании динамической формы', e);
        }
      }
    }
  };

  const setFormContextAndFamily = (
    formConfig: Array<string | null> | undefined,
    family: FamilyPageConfig,
  ) => {
    if (family && family?.property) {
      formicFromContext!.formik.setFieldValue(
        `${props.config.formPath}.${fieldName}.${family.property}`,
        family?.value,
      );
    }

    if (!Array.isArray(formConfig)) {
      return;
    }
    const [configName, id, type] = formConfig;
    if (!(configName || id || type)) {
      return;
    }
    const newFormConfig = {
      configName,
      id,
      type,
    };
    formicFromContext!.formik.setFieldValue(
      `${props.config.formPath}.${fieldName}.formContext`,
      newFormConfig,
    );
  };

  useEffect(() => {
    setFullPath(`${formPath ? `${formPath}.` : ''}${fieldName || ''}`);
  }, [formPath, fieldName]);

  useEffect(() => {
    const { areaName } = props;
    registerWidgetContext(areaName!, (config: any, ...formConfig) => {
      if (formOptions?.formOptions && props.config?.formPath) {
        setPageOptionsToForm(config);
        console.log(config, formConfig);
        setFormContextAndFamily(formConfig[0] as Array<string | null>, config?.family || null);
      }
      // pageStateConfig
      const newStatePageConfig: AnyObject = cloneDeep(formConfig);
      newStatePageConfig[fullPath] = config;
      dispatch(setFromConfigState(newStatePageConfig));
      setPageConfig(config as DynamicPageViewConfig);
    });
    registerWidgetContext(`${RESET_AREA_FORM}${areaName}`, (config: any, ...formConfig) => {
      if (formicFromContext?.formik) {
        formicFromContext.formik.setFieldValue(fullPath, null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageConfig, fullPath]);

  useEffect(() => {
    if (props.config?.options?.page_key?.value) {
      loadPageConfig(
        moduleKey,
        { configName: props.config.options.page_key.value, id, type },
        controller.signal,
      )
        .then((data) => {
          setPageConfig(data);
        })
        .catch(() => {
          setPageConfig(props.config);
        });
    } else {
      setPageConfig(props.config);
    }

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryParam && IsLoadAction(currentAction.actionType)) {
      getDataInstance(moduleKey, queryParam, controller.signal)
        .then((data) => {
          if (data) {
            setPageData(data);
          } else {
            setPageData(null);
          }
        })
        .catch(() => {
          setPageData(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  useEffect(() => {
    const queryParams = { configName: configPath, id, type };
    if (queryParams.configName && queryParams.id && queryParams.type) {
      getDataInstance(moduleKey, queryParams, controller.signal)
        .then((data) => {
          if (data) {
            setPageData(data);
          } else {
            setPageData(null);
          }
        })
        .catch(() => {
          setPageData(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (pageConfig) {
      setGridConfig(pageConfigToGridParams(pageConfig));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageConfig]);

  return (
    <ConfigNameContext.Provider value={params.configPath}>
      <PageDataContext.Provider value={pageData}>
        {pageConfig && (
          <div className={style.PageWrapper}>
            <GridTemplateComponent
              gridTemplateAreas={gridConfig.gridTemplateAreas}
              gridTemplateColumns={gridConfig.gridTemplateColumns}
              gridTemplateRows={gridConfig.gridTemplateRows}
              gridTemplateColumnGap={gridConfig.gridTemplateColumnGap}
              gridTemplateRowGap={gridConfig.gridTemplateRowGap}
              areasConfig={pageConfig.areasConfig || []}
              viewType={pageConfig.viewType}
              formPath={fullPath}
            />
          </div>
        )}
      </PageDataContext.Provider>
    </ConfigNameContext.Provider>
  );
};

export default PageView;
