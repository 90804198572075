import React, { FC, useContext } from 'react';
import { ViewConstructorWidgetFormProps } from '../ViewConstructorForm';
import ConstructorFormikContext from '../../contexts/ConstructorFormikContext';
import { getFieldByFullPath } from '../../../helpers/formikHelpers';
import {
  InputLabel,
  TextField,
  Accordion,
  AccordionSummary,
  Select,
  MenuItem,
} from '@mui/material';
import ConstructorFieldNamesContext from '../../contexts/ConstructorFieldNamesContext';
import { Checkbox } from 'antd';
import DataSourceConstructor from './DataSourceConstructor/DataSourceConstructor';
import formStyles from '../ViewConstructorForms.module.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { GridParameterInput } from '../../../ConstructorProperties/GridParameterInput';
import { WidgetTypesEnum } from '../../../../../models/Widget/WidgetTypes.enum';
import SmartWidgetOption from './SmartWidgetOption/SmartWidgetOption';

const types = [
  { value: WidgetTypesEnum.INPUT_STRING, label: 'Строка' },
  { value: WidgetTypesEnum.INPUT_NUMBER, label: 'Число' },
];

const InputStringConstructorForm: FC<ViewConstructorWidgetFormProps> = ({ areaPath }) => {
  const formik = useContext(ConstructorFormikContext);
  const fieldNames = useContext(ConstructorFieldNamesContext);

  return (
    <div className={formStyles.FormFieldsWrapper}>
      <div>
        <InputLabel>Имя поля</InputLabel>
        <Select
          size="small"
          style={{ width: 220 }}
          id={`${areaPath}.options.fieldName.value`}
          name={`${areaPath}.options.fieldName.value`}
          value={getFieldByFullPath(formik!.values, `${areaPath}.options.fieldName.value`)}
          onChange={formik!.handleChange}
          onBlur={formik!.handleBlur}
        >
          {fieldNames.map((fieldName) => (
            <MenuItem key={fieldName} value={fieldName}>
              {fieldName}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div>
        <InputLabel>Подпись</InputLabel>
        <TextField
          size="small"
          style={{ width: '100%' }}
          id={`${areaPath}.options.label.value`}
          name={`${areaPath}.options.label.value`}
          value={getFieldByFullPath(formik!.values, `${areaPath}.options.label.value`)}
          onChange={formik!.handleChange}
          onBlur={formik!.handleBlur}
          type={'text'}
        />
      </div>
      <div>
        <InputLabel>Тип</InputLabel>
        <Select
          size="small"
          style={{ width: 220 }}
          id={`${areaPath}.type`}
          name={`${areaPath}.type`}
          value={getFieldByFullPath(formik!.values, `${areaPath}.type`)}
          onChange={formik!.handleChange}
          onBlur={formik!.handleBlur}
        >
          {types.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ width: 220 }}>
        <Checkbox
          onChange={formik!.handleChange}
          id={`${areaPath}.options.isRequired.value`}
          name={`${areaPath}.options.isRequired.value`}
          checked={!!getFieldByFullPath(formik!.values, `${areaPath}.options.isRequired.value`)}
        >
          <InputLabel>Обязательное поле</InputLabel>
        </Checkbox>
      </div>

      <SmartWidgetOption areaPath={areaPath} />

      <DataSourceConstructor
        style={{ width: '90%' }}
        formPath={`${areaPath}.options.dataSource.value`}
      />

      <div className={formStyles.WidgetStyleAccordionWrapper}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id={`Textarea-style`}
          >
            <InputLabel style={{ borderBottom: 'none', padding: 0, width: '100%' }}>
              Стлили
            </InputLabel>
          </AccordionSummary>

          <div className={formStyles.WidgetStyleWrapper}>
            <div>
              <InputLabel>Ширина</InputLabel>
              <GridParameterInput
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.width.value`)}
                onChange={(newValue: string) => {
                  formik!.setFieldValue(`${areaPath}.options.width.value`, newValue);
                }}
              />
            </div>

            <div>
              <InputLabel>Высота</InputLabel>
              <GridParameterInput
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.height.value`)}
                onChange={(newValue: string) => {
                  formik!.setFieldValue(`${areaPath}.options.height.value`, newValue);
                }}
              />
            </div>

            <div>
              <InputLabel>Ширина лейбла</InputLabel>
              <GridParameterInput
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.labelWidth.value`)}
                onChange={(newValue: string) => {
                  formik!.setFieldValue(`${areaPath}.options.labelWidth.value`, newValue);
                }}
              />
            </div>

            <div>
              <InputLabel>Ширина инпута</InputLabel>
              <GridParameterInput
                value={getFieldByFullPath(formik!.values, `${areaPath}.options.inputWidth.value`)}
                onChange={(newValue: string) => {
                  formik!.setFieldValue(`${areaPath}.options.inputWidth.value`, newValue);
                }}
              />
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default InputStringConstructorForm;
