import React, { FC, useContext, useEffect, useState } from 'react';
import style from './GridArea.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import { cloneDeep } from 'lodash';
import ConstructorFormikContext from '../contexts/ConstructorFormikContext';
import { useDrop } from 'react-dnd';
import { WidgetTypesEnum } from '../../../../models/Widget/WidgetTypes.enum';
import ConstructorOnWidgetDropContext from '../contexts/ConstructorOnWidgetDrop';
import FormikContext from '../../../../contexts/FormikContext';
import WidgetFactory from '../../../../utils/WidgetFactory';
import { WidgetsTranslate } from '../VidgetList/WidgetsTranslate';
import ConstructorOnAreaClickContext from '../contexts/ConstructorOnAreaClickContext';

interface GridAreaProps {
  gridAreaName: string;
  path: string;
  index: number;
}

const GridArea: FC<GridAreaProps> = ({ gridAreaName, path, index }) => {
  const formik = useContext(ConstructorFormikContext);
  const onDropContext = useContext(ConstructorOnWidgetDropContext);
  const onAreaClick = useContext(ConstructorOnAreaClickContext);

  const [fullPath, setFullPath] = useState<string>('');

  const areaConfig = formik!.values.areasConfig[index]?.widgetConfig;

  useEffect(() => {
    if (path) {
      setFullPath(`${path}.${gridAreaName}`);
    } else {
      setFullPath(gridAreaName);
    }
  }, [gridAreaName, path]);

  const deleteArea = () => {
    const areasConfig = cloneDeep(formik!.getFieldProps('areasConfig').value) as Array<{
      areaName: string;
    }>;
    const gridTemplate = cloneDeep(formik!.getFieldProps('gridTemplate').value) as Array<string>;

    const newAreasConfig = areasConfig.filter((area) => area.areaName !== gridAreaName);
    const newGridTemplate = gridTemplate.map((row) => row.replaceAll(gridAreaName, '.'));

    formik!.setFieldValue('areasConfig', newAreasConfig);
    formik!.setFieldValue('gridTemplate', newGridTemplate);
    onDropContext('', WidgetTypesEnum.NONE);
  };

  const onDrop = (type: WidgetTypesEnum) => {
    onDropContext(fullPath, type);
  };

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: Object.values(WidgetTypesEnum),
      drop(_item, monitor) {
        onDrop(monitor.getItemType() as WidgetTypesEnum);
        return undefined;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        draggingColor: monitor.getItemType(),
      }),
    }),
    [onDrop],
  );

  return (
    <div
      onClick={() => {
        onAreaClick(fullPath, areaConfig.type);
      }}
      ref={drop}
      style={{
        gridArea: gridAreaName,
      }}
      className={style.dropContainer}
    >
      <div className={style.areaToolbar}>
        <div>Область: {gridAreaName}</div>
        <div>
          {areaConfig?.type ? `Виджет: ${(WidgetsTranslate as any)[areaConfig?.type]}` : ''}
        </div>
        <div>
          {areaConfig?.options?.fieldName?.value
            ? `Имя поля: ${areaConfig.options.fieldName.value}`
            : ''}
        </div>
        <div className={style.iconContainer}>
          <DeleteIcon className={style.CloseButton} onClick={deleteArea} />
        </div>
      </div>
      {areaConfig && (
        <div className={style.areaWidget}>
          <FormikContext.Provider value={undefined}>
            {WidgetFactory(areaConfig, gridAreaName, fullPath)}
          </FormikContext.Provider>
        </div>
      )}
    </div>
  );
};

export default GridArea;
