import { useContext, useEffect, useState } from 'react';
import PageDataContext from '../contexts/PageDataContext';
import FormContext from '../contexts/FormContextType';
import CurrentActionContext from '../contexts/CurrentActionContext';
import { StateModel } from '../redux/models/state.model';
import { useSelector } from 'react-redux';

const useContextByFieldName = (fieldPath: string | undefined) => {
  const pageData = useContext(PageDataContext)?.data;
  const modalState = useSelector((state: StateModel) => state.modalState);
  const formContext = useContext(FormContext)?.context;
  const currentAction = useContext(CurrentActionContext);
  const [contextByFieldName, setContextByFieldName] = useState<any>(undefined);

  useEffect(() => {
    const contextUrls: Array<string> = fieldPath?.split('.') || [];

    const data = modalState.isOpen ? modalState.context : formContext;
    const newContext = contextUrls.reduce((data: any, path) => data?.[path], data);
    setContextByFieldName(newContext);
    // if (newContext) {
    //   setContextByFieldName(newContext);
    // } else if (IsLoadAction(currentAction.actionType)) {
    //   setContextByFieldName(
    //     contextUrls.reduce(
    //       (data: any, path) => data?.[path],
    //       modalState.isOpen ? modalState.context : formContext,
    //     ),
    //   );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldPath, currentAction]);

  return contextByFieldName;
};

export default useContextByFieldName;
