import React, { CSSProperties, FC, useContext, useEffect, useState } from 'react';
import style from './GridArrayCellForm.module.scss';
import { TextField } from '@mui/material';
import { cloneDeep } from 'lodash';
import { getFieldByFullPath } from '../../helpers/formikHelpers';
import { saveAreaToGrid } from '../../helpers/gridHelpers';
import ConstructorAreasCount from '../contexts/ConstructorAreasCount';
import ConstructorFormikContext from '../contexts/ConstructorFormikContext';

export type GridCellPosition = { rowIndex: number; colIndex: number } | null;

interface GridArrayCellForm {
  startCell: GridCellPosition;
  endCell: GridCellPosition;
  onClose: () => void;
}

const GridArrayCellForm: FC<GridArrayCellForm> = ({ startCell, endCell, onClose }) => {
  const formik = useContext(ConstructorFormikContext);
  const areasCountContext = useContext(ConstructorAreasCount);
  const formikValue = formik!.values;

  const [areaName, setAreaName] = useState<string>(`area${areasCountContext!.areaCount + 1}`);
  const [disableStatus, setDisableStatus] = useState<boolean>(false);
  const [formStyle, setFormStyle] = useState<CSSProperties>({ display: 'none' });

  useEffect(() => {
    if (!(startCell?.colIndex && endCell?.colIndex && startCell?.rowIndex && endCell?.rowIndex)) {
      return;
    }

    if (
      (startCell.rowIndex > endCell.rowIndex && startCell.colIndex === endCell.colIndex) ||
      (startCell.rowIndex === endCell.rowIndex && startCell.colIndex > endCell.colIndex) ||
      (startCell.rowIndex > endCell.rowIndex && startCell.colIndex > endCell.colIndex)
    ) {
      setFormStyle({
        gridColumnStart: endCell.colIndex,
        gridColumnEnd: startCell.colIndex + 1,
        gridRowStart: endCell.rowIndex,
        gridRowEnd: startCell.rowIndex + 1,
      });
    } else if (startCell.rowIndex > endCell.rowIndex && startCell.colIndex < endCell.colIndex) {
      setFormStyle({
        gridColumnStart: startCell.colIndex,
        gridColumnEnd: endCell.colIndex + 1,
        gridRowStart: endCell.rowIndex,
        gridRowEnd: startCell.rowIndex + 1,
      });
    } else if (startCell.rowIndex < endCell.rowIndex && startCell.colIndex > endCell.colIndex) {
      setFormStyle({
        gridColumnStart: endCell.colIndex,
        gridColumnEnd: startCell.colIndex + 1,
        gridRowStart: startCell.rowIndex,
        gridRowEnd: endCell.rowIndex + 1,
      });
    } else {
      setFormStyle({
        gridColumnStart: startCell.colIndex,
        gridColumnEnd: endCell.colIndex + 1,
        gridRowStart: startCell.rowIndex,
        gridRowEnd: endCell.rowIndex + 1,
      });
    }
  }, [startCell, endCell]);

  useEffect(() => {
    setDisableStatus(areaName.length < 1 || !Number.isNaN(parseFloat(areaName)));
  }, [areaName]);

  const handleSave = () => {
    const gridTemplate = cloneDeep(
      getFieldByFullPath(formikValue, `gridTemplate`),
    ) as Array<string>;
    const { gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd } = formStyle;

    const newGrid = saveAreaToGrid(
      gridRowStart as number,
      gridRowEnd as number,
      gridColumnStart as number,
      gridColumnEnd as number,
      areaName,
      gridTemplate,
    );
    formik!.setFieldValue(`gridTemplate`, newGrid);

    const areasConfig = cloneDeep(formik!.getFieldProps(`areasConfig`).value);
    if (areasConfig.findIndex((area: { areaName: string }) => area.areaName === areaName) === -1) {
      areasConfig.push({ areaName });
      formik!.setFieldValue(`areasConfig`, areasConfig);
    }

    areasCountContext?.setAreaCount((count) => count + 1);
    onClose();
  };

  return (
    <div className={style.FormContainer} style={formStyle}>
      <TextField
        value={areaName}
        onChange={(e) => {
          setAreaName(e.target.value);
        }}
      />

      <input
        className={disableStatus ? style.DisabledSubmitButton : style.SubmitButton}
        type="text"
        readOnly={true}
        disabled={disableStatus}
        value={'+'}
        onClick={() => handleSave()}
      />

      <input
        className={style.CloseButton}
        type="text"
        readOnly={true}
        value={'Х'}
        onClick={() => onClose()}
      />
    </div>
  );
};

export default GridArrayCellForm;
