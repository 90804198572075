import { WidgetTypesEnum } from '../../../../models/Widget/WidgetTypes.enum';
import { AnyObject } from '../../../../models/AnyObject';
import { PbsDynamicWidgetValue } from '../../../../models/Widget/PbsDynamicWidgetValue';
import { DataFunction } from '../../../../models/Widget/DataFunction';

export const configureFormValueByWidgetType = (widgetType: WidgetTypesEnum): AnyObject => {
  switch (widgetType) {
    case WidgetTypesEnum.LABEL: {
      return {
        type: WidgetTypesEnum.LABEL,
        value: WidgetTypesEnum.LABEL,
        widgetFlow: configurePBSValue('right'),
        verticalFlow: configurePBSValue('auto'),
        options: {
          dataSource: configurePBSValue<Array<DataFunction>>([]),
          fieldName: configurePBSValue<string>(''),
          fontSize: configurePBSValue<number>(14),
          height: configurePBSValue<string>('100%'),
          labelType: configurePBSValue<'DYNAMIC' | 'STATIC'>('STATIC'),
          width: configurePBSValue<string>('100%'),
          fontWeight: configurePBSValue<string | number>('normal'),
        },
      };
    }
    case WidgetTypesEnum.TEXTAREA: {
      return {
        type: WidgetTypesEnum.TEXTAREA,
        options: {
          label: configurePBSValue<string>('label'),
          dataSource: configurePBSValue<Array<DataFunction>>([]),
          fieldName: configurePBSValue<string>(''),
          isRequired: configurePBSValue<boolean>(false),
          height: configurePBSValue<string>('100%'),
          width: configurePBSValue<string>('100%'),
          inputWidth: configurePBSValue<string>('50%'),
          labelWidth: configurePBSValue<string>('50%'),
        },
      };
    }
    case WidgetTypesEnum.INPUT_NUMBER:
    case WidgetTypesEnum.INPUT_STRING: {
      return {
        type: widgetType,
        options: {
          label: configurePBSValue<string>('label'),
          dataSource: configurePBSValue<Array<DataFunction>>([]),
          fieldName: configurePBSValue<string>(''),
          isRequired: configurePBSValue<boolean>(false),
          height: configurePBSValue<string>('100%'),
          width: configurePBSValue<string>('100%'),
          inputWidth: configurePBSValue<string>('50%'),
          labelWidth: configurePBSValue<string>('50%'),
        },
      };
    }
    case WidgetTypesEnum.SELECT: {
      return {
        type: WidgetTypesEnum.SELECT,
        options: {
          label: configurePBSValue<string>('label'),
          dataSource: configurePBSValue<Array<DataFunction>>([]),
          fieldName: configurePBSValue<string>(''),
          isRequired: configurePBSValue<boolean>(false),
          height: configurePBSValue<string>('100%'),
          width: configurePBSValue<string>('100%'),
          inputWidth: configurePBSValue<string>('50%'),
          labelWidth: configurePBSValue<string>('50%'),
          parentWidgets: configurePBSValue<Array<string>>([]),
          endpoint: configurePBSValue<string>(''),
          contextFieldPath: null,
          events: null,
          contextRepeatable: null,
          contextWidget: null,
          filter: null,
          smartWidget: null,
        },
      };
    }
    case WidgetTypesEnum.INPUT_BOOLEAN: {
      return {
        type: WidgetTypesEnum.INPUT_BOOLEAN,
        options: {
          label: configurePBSValue<string>('label'),
          fieldName: configurePBSValue<string>(''),
        },
      };
    }
    case WidgetTypesEnum.INPUT_DATE: {
      return {
        type: WidgetTypesEnum.INPUT_DATE,
        options: {
          label: configurePBSValue<string>('label'),
          fieldName: configurePBSValue<string>(''),
          isRequired: configurePBSValue<boolean>(false),
          height: configurePBSValue<string>('100%'),
          width: configurePBSValue<string>('100%'),
          inputWidth: configurePBSValue<string>('50%'),
          labelWidth: configurePBSValue<string>('50%'),
        },
      };
    }
    case WidgetTypesEnum.CODE_EDITOR_GROOVY:
    case WidgetTypesEnum.INPUT_JSON: {
      return {
        type: widgetType,
        options: {
          label: configurePBSValue<string>('label'),
          fieldName: configurePBSValue<string>(''),
          isRequired: configurePBSValue<boolean>(false),
        },
      };
    }
    default: {
      return {};
    }
  }
};

const configurePBSValue = <T = any>(value: T): PbsDynamicWidgetValue<T> => ({ value });
