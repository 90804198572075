import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ModuleRouteInfo, StateModel } from '../../redux/models/state.model';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import {
  fetchDataModalInstance,
  resetDataInstance,
} from '../../redux/reducers/DataInstanceReducer';
import { DynamicPageViewConfig } from '../../models/Page/DynamicPageViewConfig';
import { loadPageConfig } from '../../api/resource';
import GridTemplateComponent from '../GridTemplate/GridTemplate.component';
import { initialGridConfig } from '../../mock/InitialGridConfig';
import { pageConfigToGridParams } from '../../utils/pageConfigToGridPrams';
import { ActionTypes } from '../../models/enums/EventTypes.enum';

interface ModalWrapperProps {
  isOpen: boolean;
  activeAction: any;
  action: any;
  onCancel: () => void;
}

const ModalWrapper: FC<ModalWrapperProps> = ({ isOpen, activeAction, onCancel, action }) => {
  const dispatch = useAppDispatch();

  const moduleRouteInfo: ModuleRouteInfo = useSelector(
    (state: StateModel) => state.moduleRouteInfo,
  );
  const { moduleKey } = moduleRouteInfo;

  const controller = new AbortController();
  const [pageConfig, setPageConfig] = useState<DynamicPageViewConfig>();
  const [gridConfig, setGridConfig] = useState(initialGridConfig);

  useEffect(() => {
    const { configName, type, id } = activeAction;
    const queryParams = { configName, type, id };
    loadPageConfig(moduleKey, queryParams, controller.signal)
      .then((data) => {
        setPageConfig(data);
      })
      .catch(() => {});
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAction]);

  useEffect(() => {
    if (action === ActionTypes.LOAD_DATA_AND_DISPLAY_FORM) {
      dispatch(fetchDataModalInstance({ moduleKey, activeAction }));
    } else {
      dispatch(resetDataInstance());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAction]);

  useEffect(() => {
    if (pageConfig) {
      setGridConfig(pageConfigToGridParams(pageConfig));
    }
  }, [pageConfig]);

  return (
    <Modal open={isOpen} footer={null} centered onCancel={onCancel} width={1000}>
      {pageConfig && (
        <GridTemplateComponent
          gridTemplateAreas={gridConfig.gridTemplateAreas}
          gridTemplateColumns={gridConfig.gridTemplateColumns}
          gridTemplateRows={gridConfig.gridTemplateRows}
          gridTemplateColumnGap={gridConfig.gridTemplateColumnGap}
          gridTemplateRowGap={gridConfig.gridTemplateRowGap}
          areasConfig={pageConfig.areasConfig || []}
          viewType={pageConfig.viewType}
        />
      )}
    </Modal>
  );
};

export default ModalWrapper;
