export interface DataFunction {
  type: AvailableFunctionsType;
  returnType?: DataFunctionType;
  // Скорее всего поле не понадобится
  target?: any;
  args: Array<FunctionArgument>;
}

export interface FunctionArgument {
  type: 'CONST' | 'FIELD';
  value: any;
}

export interface TitleArgsModel<T = any> {
  title: string;
  value: T;
}

export enum DataFunctionType {
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
}

export enum AvailableFunctionsType {
  GET_BY_FIELD_NAME = 'GET_BY_FIELD_NAME',
  CONCAT = 'CONCAT',
  SLICE = 'SLICE',
  UPPERCASE = 'UPPERCASE',
  LOWERCASE = 'LOWERCASE',
}

export enum DataSourceFunctionsTranslate {
  GET_BY_FIELD_NAME = 'Взять поле',
  CONCAT = 'Объеденить',
  SLICE = 'Обрезать',
  UPPERCASE = 'Верхний регистр',
  LOWERCASE = 'Нижний регистр',
}

export enum AvailableArgsTypes {
  FIELD = 'FIELD',
  CONST = 'CONST',
}

export interface AvailableDataFunction {
  title?: string;
  type: AvailableFunctionsType;
  returnType: DataFunctionType;
  argsConfigs: Array<{
    title: string;
    type: AvailableArgsTypes;
  }>;
}

export const MIN_ARGS_BY_TYPE: Record<AvailableFunctionsType, number> = {
  CONCAT: 1,
  GET_BY_FIELD_NAME: 1,
  LOWERCASE: 1,
  SLICE: 1,
  UPPERCASE: 1,
};

export const MAX_ARGS_BY_TYPE: Record<AvailableFunctionsType, number> = {
  CONCAT: Infinity,
  GET_BY_FIELD_NAME: 1,
  LOWERCASE: 1,
  SLICE: 2,
  UPPERCASE: 1,
};

export const availableDataFunctions: Array<AvailableDataFunction> = [
  {
    title: 'Взять имя',
    type: AvailableFunctionsType.GET_BY_FIELD_NAME,
    returnType: DataFunctionType.STRING,
    argsConfigs: [
      {
        title: 'Поле объекта',
        type: AvailableArgsTypes.FIELD,
      },
    ],
  },
  {
    title: 'Объеденить',
    type: AvailableFunctionsType.CONCAT,
    returnType: DataFunctionType.STRING,
    argsConfigs: [
      {
        title: 'Поле объекта',
        type: AvailableArgsTypes.FIELD,
      },
    ],
  },
  {
    title: 'Обрезать',
    type: AvailableFunctionsType.SLICE,
    returnType: DataFunctionType.STRING,
    argsConfigs: [
      {
        title: 'Поле объекта',
        type: AvailableArgsTypes.FIELD,
      },
    ],
  },
  {
    title: 'Верхний регистр',
    type: AvailableFunctionsType.UPPERCASE,
    returnType: DataFunctionType.STRING,
    argsConfigs: [
      {
        title: 'Поле объекта',
        type: AvailableArgsTypes.FIELD,
      },
    ],
  },
  {
    title: 'Нижний регистр',
    type: AvailableFunctionsType.LOWERCASE,
    returnType: DataFunctionType.STRING,
    argsConfigs: [
      {
        title: 'Поле объекта',
        type: AvailableArgsTypes.FIELD,
      },
    ],
  },
];
