import { useContext, useEffect } from 'react';
import PageDataContext from '../contexts/PageDataContext';
import { getFieldByFullPath } from '../components/Widgets/helpers/formikHelpers';
import { AnyObject } from '../models/AnyObject';
import FormikContext from '../contexts/FormikContext';

export const useSetDefaultWidgetValue = (fullPath: string) => {
  const pageData = useContext(PageDataContext)?.data;
  const formicFromContext = useContext(FormikContext);

  useEffect(() => {
    const widgetValue = getFieldByFullPath(pageData as AnyObject, fullPath);
    if (widgetValue && formicFromContext?.formik) {
      formicFromContext.formik.setFieldValue(fullPath, widgetValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);
};
