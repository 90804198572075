import React, { FC, useContext, useEffect, useState } from 'react';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import style from './ViewConstructorWidget.module.scss';
import ConstructorProperties from '../ConstructorProperties/ConstructorProperties';
import { GridView } from './GridView';
import FormikContext from '../../../contexts/FormikContext';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { cloneDeep } from 'lodash';
import ConstructorAreasCount from './contexts/ConstructorAreasCount';
import { FormikProps, useFormik } from 'formik';
import ConstructorFormikContext from './contexts/ConstructorFormikContext';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import { IsLoadAction } from '../../../utils/isLoadAction';
import CurrentActionContext from '../../../contexts/CurrentActionContext';
import ViewConstructorWidgetList from './VidgetList/ViewConstructorWidgetList';
import ViewConstructorFormTree from './FormTree/ViewConstructorFormTree';
import ViewConstructorForm from './ViewConstructorForm/ViewConstructorForm';
import { WidgetTypesEnum } from '../../../models/Widget/WidgetTypes.enum';
import ConstructorOnWidgetDropContext from './contexts/ConstructorOnWidgetDrop';
import { AnyObject } from '../../../models/AnyObject';
import { configureFormValueByWidgetType } from './utils/configureFormValueByWidgetType';
import QueryParamsContext from '../../../contexts/QueryParamsContext';
import { getObjectFieldNames, getSelectableData } from '../../../api/resource';
import { ModuleRouteInfo } from '../../../redux/models/state.model';
import { useParams } from 'react-router-dom';
import ConstructorFieldNamesContext from './contexts/ConstructorFieldNamesContext';
import ConstructorOnAreaClickContext from './contexts/ConstructorOnAreaClickContext';
import { selectOption } from '../SelectWidget/SelectWidget';
import ConstructorSelectableDatasourceContext from './contexts/ConstructorSelectableDatasourceContext';
import { SelectableItemsMapper } from '../../../utils/selectableItemsMapper';
import ConstructorMethodsContext from './contexts/ConstructorMethodsContext';

export interface ViewConstructorWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;
}

enum SidebarEnum {
  tree = 'tree',
  widgets = 'widgets',
  properties = 'properties',
  form = 'form',
  none = 'none',
}

const ViewConstructorWidget: FC<WidgetProps<ViewConstructorWidgetOptions>> = (props) => {
  const formikFromContext = useContext(FormikContext);
  const currentAction = useContext(CurrentActionContext);

  const [fieldName] = valueFromConfig(
    props.config?.options,
    'fieldName',
    'contextFieldPath',
    'additionalProperties',
    'height',
    'width',
  );

  const formik: FormikProps<any> = useFormik({
    initialValues: {
      rowSize: '1fr',
      columnSize: '1fr',
      gridTemplate: ['.'],
      areasConfig: [],
      columnGap: '0px',
      rowGap: '0px',
    },
    onSubmit: (values) => {},
  });

  const formikValue = formik.values;
  const rowSize = formikValue.rowSize.split(' ').length;
  const columnSize = formikValue.columnSize.split(' ').length;

  const queryParam = useContext(QueryParamsContext);
  const params: ModuleRouteInfo = useParams() as unknown as ModuleRouteInfo;

  const [fullPath, setFullPath] = useState<string>(`${props.config.formPath}.${fieldName}`);
  const [sidebar, setSidebar] = useState<SidebarEnum>(SidebarEnum.properties);
  const [areaCount, setAreaCount] = useState<number>(0);
  const [firstValueInit, setFirstValueInit] = useState<boolean>(true);
  const [fieldNames, setFieldNames] = useState<Array<string>>([]);
  const [selectableDatasourceOptions, setSelectableDatasourceOptions] = useState<
    Array<selectOption>
  >([]);
  const [objectMethods, setObjectMethods] = useState<Array<selectOption>>([]);

  const value = getFieldByFullPath(formikFromContext?.formik?.values || {}, fullPath);

  useEffect(() => {
    if (queryParam?.id) {
      const filterParams = {
        filter_info: [{ paramName: 'context', type: 'EQUALS', paramValue: queryParam.id }],
        page_info: { pageIndex: 0, pageSize: 1000 },
      };

      getObjectFieldNames(params.moduleKey, filterParams).then((response) => {
        setFieldNames((response?.data || []).map((item: AnyObject) => item.title));
      });

      getSelectableData(
        params.moduleKey,
        'businessObjectDataSetSelectableService',
        filterParams,
      ).then((response) => {
        setSelectableDatasourceOptions(SelectableItemsMapper(response.data));
      });

      getSelectableData(
        params.moduleKey,
        'businessObjectFunctionsSelectableService',
        filterParams,
      ).then((response) => {
        setObjectMethods(SelectableItemsMapper(response.data));
      });
    }
  }, [queryParam?.id]);

  useEffect(() => {
    if (value && firstValueInit && IsLoadAction(currentAction.actionType)) {
      setFirstValueInit(() => false);
      formik.setValues(JSON.parse(value));
    }
    if (!IsLoadAction(currentAction.actionType)) {
      setFirstValueInit(() => false);
    }
  }, [value, firstValueInit]);

  useEffect(() => {
    setFullPath(`${props.config.formPath}.${fieldName}`);
  }, [fieldName, props.config.formPath]);

  useEffect(() => {
    formikFromContext?.formik?.setFieldValue?.(fullPath, JSON.stringify(formikValue));
  }, [formikValue]);

  useEffect(() => {
    if (firstValueInit) {
      return;
    }

    let gridTemplate = cloneDeep(formikValue.gridTemplate) as Array<string>;
    const rows = formikValue.rowSize.split(' ');
    const columns = formikValue.columnSize.split(' ');

    if (!(gridTemplate && rows && columns)) {
      return;
    }

    if (rows.length > gridTemplate.length) {
      gridTemplate.push(Array.from({ length: columns.length }, () => '.').join(' '));
    }

    if (columns.length > gridTemplate[0].split(' ').length) {
      gridTemplate = gridTemplate.map((row) => `${row} .`);
    }

    formik.setFieldValue(`gridTemplate`, gridTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSize, columnSize]);

  const handleSidebarClick = (value: SidebarEnum) => {
    if (value === sidebar) {
      setSidebar(SidebarEnum.none);
      return;
    }
    setSidebar(value);
  };

  const [currentFormType, setCurrentFormType] = useState<WidgetTypesEnum>(WidgetTypesEnum.NONE);
  const [currentFormPath, setCurrentFormPath] = useState<string>('');

  const onWidgetDrop = (areaPath: string, widgetType: WidgetTypesEnum) => {
    if (widgetType === WidgetTypesEnum.NONE) {
      setCurrentFormPath('');
      setCurrentFormType(widgetType);
    }

    // TODO доделать для вложенных арий
    const areaIndex = formik.values.areasConfig.findIndex(
      (area: AnyObject) => area.areaName === areaPath,
    );
    const newFormPath = `areasConfig.${areaIndex}.widgetConfig`;
    const newFormValue = configureFormValueByWidgetType(widgetType);
    formik.setFieldValue(newFormPath, newFormValue).then(() => {
      setCurrentFormType(widgetType);
      setCurrentFormPath(newFormPath);
      setSidebar(SidebarEnum.form);
    });
  };

  const onAreaClick = (areaPath: string, widgetType: WidgetTypesEnum) => {
    const areaIndex = formik.values.areasConfig.findIndex(
      (area: AnyObject) => area.areaName === areaPath,
    );
    const newFormPath = `areasConfig.${areaIndex}.widgetConfig`;
    setCurrentFormType(widgetType);
    setCurrentFormPath(newFormPath);
    setSidebar(SidebarEnum.form);
  };

  const handleTreeSelect = (
    widgetPath: string,
    type: 'WIDGET' | 'AREA',
    widgetType: WidgetTypesEnum = WidgetTypesEnum.BLANK,
  ) => {
    if (type === 'WIDGET') {
      setCurrentFormType(widgetType);
      setCurrentFormPath(widgetPath);
      setSidebar(SidebarEnum.form);
    }
  };

  return (
    <div className={style.viewConstructor}>
      <ConstructorAreasCount.Provider value={{ areaCount, setAreaCount }}>
        <ConstructorFormikContext.Provider value={formik}>
          <ConstructorOnWidgetDropContext.Provider value={onWidgetDrop}>
            <ConstructorOnAreaClickContext.Provider value={onAreaClick}>
              <ConstructorFieldNamesContext.Provider value={fieldNames}>
                <ConstructorSelectableDatasourceContext.Provider
                  value={selectableDatasourceOptions}
                >
                  <ConstructorMethodsContext.Provider value={objectMethods}>
                    <>
                      <div className={style.viewConstructor__navItems}>
                        <button
                          className={sidebar === SidebarEnum.properties ? style.selectedButton : ''}
                          onClick={() => handleSidebarClick(SidebarEnum.properties)}
                        >
                          Свойства
                        </button>
                        <button
                          className={sidebar === SidebarEnum.tree ? style.selectedButton : ''}
                          onClick={() => handleSidebarClick(SidebarEnum.tree)}
                        >
                          Дерево
                        </button>
                        <button
                          className={sidebar === SidebarEnum.widgets ? style.selectedButton : ''}
                          onClick={() => handleSidebarClick(SidebarEnum.widgets)}
                        >
                          Виджеты
                        </button>
                        <button
                          className={sidebar === SidebarEnum.form ? style.selectedButton : ''}
                          onClick={() => handleSidebarClick(SidebarEnum.form)}
                        >
                          Форма
                        </button>
                      </div>

                      {sidebar !== SidebarEnum.none && (
                        <div className={style.viewConstructor__tree}>
                          {(() => {
                            switch (sidebar) {
                              case SidebarEnum.properties:
                                return <ConstructorProperties />;
                              case SidebarEnum.widgets:
                                return <ViewConstructorWidgetList />;
                              case SidebarEnum.tree:
                                return (
                                  <ViewConstructorFormTree
                                    currentFormPath={currentFormPath}
                                    handleTreeSelect={handleTreeSelect}
                                  />
                                );
                              case SidebarEnum.form:
                                return (
                                  <ViewConstructorForm
                                    areaPath={currentFormPath}
                                    widgetType={currentFormType}
                                  />
                                );
                              default:
                                return <></>;
                            }
                          })()}
                        </div>
                      )}

                      <div className={style.viewConstructor__viewArea}>
                        <GridView path={''} />
                      </div>
                    </>
                  </ConstructorMethodsContext.Provider>
                </ConstructorSelectableDatasourceContext.Provider>
              </ConstructorFieldNamesContext.Provider>
            </ConstructorOnAreaClickContext.Provider>
          </ConstructorOnWidgetDropContext.Provider>
        </ConstructorFormikContext.Provider>
      </ConstructorAreasCount.Provider>
    </div>
  );
};

export default ViewConstructorWidget;
